import axios from 'services/api/axios';
import { Pagination } from '../types/pagination';
import { ThirdPartyAudience, ThirdPartyAudienceRequestData } from '../types/third-party-audiences';

export default {
  getList: async (queryString?: string): Promise<Pagination<ThirdPartyAudience>> => {
    const response = await axios.get(`/audiences/segments/dmps?${queryString}`);

    return response.data;
  },
  get: async (id: ThirdPartyAudience['id']): Promise<ThirdPartyAudience> => {
    const response = await axios.get(`/audiences/segments/dmps/${id}`);

    return response.data;
  },
  create: async (data: ThirdPartyAudienceRequestData): Promise<ThirdPartyAudience['id']> => {
    const response = await axios.post('/audiences/segments/dmps', data);

    return response.data;
  },
  update: async (
    id: ThirdPartyAudience['id'],
    data: Partial<ThirdPartyAudienceRequestData>,
  ): Promise<ThirdPartyAudience['id']> => {
    const response = await axios.put(`/audiences/segments/dmps/${id}`, data);

    return response.data;
  },
};
