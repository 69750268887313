import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// material-ui
import { Autocomplete, Box, ClickAwayListener, InputAdornment, Stack, TextField } from '@mui/material';
// components
import { Checkbox } from 'components';
// icons
import { ReactComponent as CancelCircleIcon } from 'assets/icons/cancel-circle.svg';
import { ReactComponent as CheckCircleBrokenIcon } from 'assets/icons/check-circle-broken.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
// styles
import {
  Label,
  Placeholder,
  StyledAutocompletePopper,
  StyledButton,
  StyledDivider,
  StyledPopper,
  ToggleSelectButton,
  ValuesPreview,
} from './styled';
import { gray, primary } from 'themes/colors';
// types
import { AutocompleteMultipleProps, PopperComponentProps } from './types';

const PopperComponent: React.FC<PopperComponentProps> = ({ disablePortal, anchorEl, open, ...other }) => (
  <StyledAutocompletePopper {...other} />
);

const AutocompleteMultiple: React.FC<AutocompleteMultipleProps> = ({
  label,
  field: { name, value, ...otherField },
  form: { setFieldValue },
  options,
  placeholder,
  limitTags = 3,
}) => {
  const { t } = useTranslation('workspace');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  };

  const handleSelectAllClick = () => {
    setFieldValue(name, options);
  };

  const handleSelectNoneClick = () => {
    setFieldValue(name, []);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'autocomplete-multiple' : undefined;

  const displayingValues = useMemo(
    () => `${value.slice(0, limitTags).join(', ')}${value.length > limitTags ? `, +${value.length - limitTags}` : ''}`,
    [value, limitTags],
  );

  return (
    <>
      <Box width='100%'>
        <StyledButton disableRipple aria-describedby={id} onClick={handleClick}>
          <Label variant='body2'>{label}</Label>

          <ValuesPreview>
            {value.length ? displayingValues : <Placeholder>{placeholder}</Placeholder>} <ChevronDownIcon />
          </ValuesPreview>
        </StyledButton>
      </Box>

      <StyledPopper id={id} open={isOpen} anchorEl={anchorEl} placement='bottom-start'>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Stack direction='row' gap={2} mb={1}>
              <ToggleSelectButton variant='contained' disableRipple onClick={handleSelectAllClick}>
                <CheckCircleBrokenIcon /> {t('Select all')}
              </ToggleSelectButton>

              <ToggleSelectButton variant='contained' disableRipple onClick={handleSelectNoneClick}>
                <CancelCircleIcon width={20} height={20} fill={primary[700]} /> {t('Select none')}
              </ToggleSelectButton>
            </Stack>

            <Autocomplete
              {...otherField}
              open
              multiple
              value={value}
              options={options}
              disableCloseOnSelect
              renderTags={() => null}
              PopperComponent={PopperComponent}
              noOptionsText={t('No values')}
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              onChange={(event, value, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }

                setFieldValue(name, value);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} label={option} />
                </li>
              )}
              renderInput={(params) => (
                <>
                  <TextField
                    autoFocus
                    fullWidth
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    placeholder={t('Search') as string}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon width={20} height={20} fill={gray[500]} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <StyledDivider />
                </>
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default memo(AutocompleteMultiple);
