// material-ui
import { Box, Dialog, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
// styles
import { gray } from 'themes/colors';
import { shadows } from 'themes/shadows';

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 100%;
    max-width: 480px;
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  width: 100%;
  gap: 8px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid ${gray[100]};
  background-color: ${gray[50]};
  margin-bottom: 20px;
`;

export const StyledToggleButton = styled(ToggleButton)`
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  border: none;
  text-transform: none;
  background-color: transparent;

  &.MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-radius: 6px;
  }

  &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
    margin: 0;
    border: none;
    border-radius: 6px;
  }

  &:hover {
    background-color: ${gray[100]};
  }

  &.Mui-selected {
    color: ${gray[700]};
    background-color: #fff;
    box-shadow: ${shadows.sm};
  }

  &.Mui-selected:hover {
    color: ${gray[700]};
    background-color: #fff;
  }
`;

export const ColumnsWrapper = styled(Box)`
  border-top: 1px solid ${gray[200]};
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  & > label {
    flex: 1 1 45%;
  }
`;
