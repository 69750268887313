import api from 'services/api';
import { OPTIONS_KEYS } from 'services/constants';

export const getOptionsConfig = (optionKey: OPTIONS_KEYS) => {
  switch (optionKey) {
    case OPTIONS_KEYS.EXCHANGES:
      return {
        api: (pageParam: number) => api.exchanges.getList(`$skip=${pageParam}&$limit=10000`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, displayName }) => ({
            value: id,
            label: displayName,
          })),
      };
    case OPTIONS_KEYS.BUNDLE_DOMAINS:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.lists.getLists(
            `$skip=${pageParam}&$limit=10000&type=bundle${
              additionalParams?.accountId ? `&accountId=${additionalParams?.accountId}` : ''
            } `,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.DEALS:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.lists.getLists(
            `$skip=${pageParam}&$limit=10000&type=deal${
              additionalParams?.accountId ? `&accountId=${additionalParams?.accountId}` : ''
            } `,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.INVENTORIES:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.lists.getLists(
            `$skip=${pageParam}&$limit=10000&type=app${
              additionalParams?.accountId ? `&accountId=${additionalParams?.accountId}` : ''
            } `,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.IP_RANGES:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.lists.getLists(
            `$skip=${pageParam}&$limit=10000&type=ip_range${
              additionalParams?.accountId ? `&accountId=${additionalParams?.accountId}` : ''
            } `,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.CARRIERS:
      return {
        api: (pageParam: number) => api.carriers.getList(`$skip=${pageParam}&$limit=10000`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, operator }) => ({
            value: id,
            label: operator,
          })),
      };
    case OPTIONS_KEYS.COUNTRIES:
      return {
        api: (pageParam: number) => api.countries.getList(`$skip=${pageParam}&$limit=250`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ isoCode2, name }) => ({
            value: isoCode2,
            label: name,
          })),
      };
    case OPTIONS_KEYS.CITIES:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.cities.getList(`$skip=${pageParam}&$limit=10&country=${additionalParams?.country}`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.GEO:
      return {
        api: (pageParam: number) => api.lists.getLists(`$skip=${pageParam}&$limit=10000&type=geo`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.REGION:
      return {
        api: (pageParam: number) => api.lists.getLists(`$skip=${pageParam}&$limit=10000&type=region`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.ZIPCODE:
      return {
        api: (pageParam: number) => api.lists.getLists(`$skip=${pageParam}&$limit=10000&type=zipcode`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.DEVICE_MODELS:
      return {
        api: (pageParam: number) => api.deviceModels.getList(`$skip=${pageParam}&$limit=10000`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ deviceBrand }) => ({
            value: deviceBrand,
            label: deviceBrand,
          })),
      };
    case OPTIONS_KEYS.ANDROID_VERSIONS:
      return {
        api: (pageParam: number) => api.osVersions.getList(`$skip=${pageParam}&$limit=10000&osName=android`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
      };
    case OPTIONS_KEYS.IOS_VERSIONS:
      return {
        api: (pageParam: number) => api.osVersions.getList(`$skip=${pageParam}&$limit=10000&osName=ios`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
      };
    case OPTIONS_KEYS.WINDOWS_VERSIONS:
      return {
        api: (pageParam: number) => api.osVersions.getList(`$skip=${pageParam}&$limit=10000&osName=windows`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
      };
    case OPTIONS_KEYS.ALL_AUDIENCES:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.allAudiences.getList(
            `$skip=${pageParam}&$limit=10000${
              additionalParams?.accountId ? `&accountId=${additionalParams?.accountId}` : ''
            } `,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name, audienceType }) => ({
            value: id,
            label: name,
            type: audienceType,
          })),
      };
    case OPTIONS_KEYS.CAMPAIGNS:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.campaigns.getList(
            `$skip=${pageParam}&$limit=10000${
              additionalParams?.accountId ? `&accountId=${additionalParams?.accountId}` : ''
            }`,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.LINES:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.lines.getList(`$skip=${pageParam}&$limit=10000&campaignId=${additionalParams?.campaignId}`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.TRACKING_GROUPS:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.trackingGroups.getList(
            `$skip=${pageParam}&$limit=10000${
              additionalParams?.accountId ? `&accountId=${additionalParams?.accountId}` : ''
            }`,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.FOLDERS:
      return {
        api: (pageParam: number, additionalParams?: any) =>
          api.folders.getList(
            `$skip=${pageParam}&$limit=10000${
              additionalParams?.accountsIds
                ? additionalParams?.accountsIds.map((item: number) => `&accountId=${item}`).join('')
                : ''
            }`,
          ),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.DEVICE_LANGUAGES:
      return {
        api: (pageParam: number) => api.deviceLanguages.getList(`$skip=${pageParam}&$limit=10000`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ code, name }) => ({
            value: code,
            label: name,
          })),
      };
    case OPTIONS_KEYS.LINE_CREATIVES:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.lines.getCreatives(Number(additionalParams?.lineId)),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, creativeName }) => ({
            value: id,
            label: creativeName,
          })),
      };
    case OPTIONS_KEYS.SEGMENTS:
      return {
        api: (pageParam: number, additionalParams?: Record<string, string>) =>
          api.segments.getList(`$skip=${pageParam}&$limit=10000&type=${additionalParams?.type}`),
        serializeOptions: (data: Record<string, string | number | boolean>[]) =>
          data.map(({ id, name, cpmDollars }) => ({
            value: id,
            label: name,
            price: cpmDollars,
          })),
      };
  }
};
export { OPTIONS_KEYS };
