import React, { memo } from 'react';
// material-ui
import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
// libs
import { getIn } from 'formik';
// styles
import { StyledFormControlLabel, StyledSwitch } from './styled';
// types
import { FormSwitchProps, SwitchProps } from './types';

export const Switch: React.FC<SwitchProps> = ({ label, ...rest }) => (
  <StyledFormControlLabel {...rest} label={label} control={<StyledSwitch disableRipple />} />
);

const FormSwitch: React.FC<FormSwitchProps> = ({
  field,
  helperText,
  description,
  form: { errors, touched },
  ...rest
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);

  return (
    <FormControl error={isError} size='small'>
      <Switch {...field} {...rest} checked={field.value} />
      {description && (
        <Box ml={7}>
          <Typography fontSize='16px' lineHeight='24px' color={grey[500]}>
            {description}
          </Typography>
        </Box>
      )}

      {helperText && !isError && <FormHelperText>{helperText}</FormHelperText>}
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default memo(FormSwitch);
