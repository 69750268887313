import { createContext, ReactNode, useContext, useState } from 'react';
import { FiltersState } from './components/Filters';

type TableContextState = {
  filters: FiltersState;
  setFilters: (filters: FiltersState) => void;
};

export const tableFiltersInitialValues: FiltersState = { domainList: [] };

const TableContext = createContext<TableContextState>({} as TableContextState);

export const useTableContext = () => useContext(TableContext);

export const TableContextProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState<FiltersState>(tableFiltersInitialValues);

  return <TableContext.Provider value={{ filters, setFilters }}>{children}</TableContext.Provider>;
};
