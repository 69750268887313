import { certisDspAxiosInstance as axios } from 'services/api/axios';
import {
  ABMAudience,
  CalculateAbmReportParams,
  CreateABMAudiencesRequestBody,
  EditABMAudiencesRequestBody,
  ReportsAbmReportParams,
} from '../types/amb-audiences';

export default {
  getList: async (queryString?: string): Promise<ABMAudience[]> => {
    const response = await axios.get(queryString ? `/abm-audiences?${queryString}` : '/abm-audiences');

    return response.data;
  },
  getOne: async (id: string | number): Promise<ABMAudience> => {
    const response = await axios.get(`/abm-audiences/${id}`);

    return response.data;
  },
  create: async (data: CreateABMAudiencesRequestBody): Promise<ABMAudience> => {
    const response = await axios.post('/abm-audiences', data);

    return response.data;
  },
  edit: async (id: string | number, data: EditABMAudiencesRequestBody): Promise<number> => {
    const response = await axios.put(`/abm-audiences/${id}`, data);

    return response.data;
  },
  delete: async (id: string | number): Promise<{ message: 'ok' }> => {
    const response = await axios.delete(`/abm-audiences/${id}`);

    return response.data;
  },
  /**
   * `deleteExternal` method can delete ABM audiences and First party audiences
   * @param {number | string} id use `externalId` for ABM audiences and `id` for First party audiences
   *
   * @returns `{message: 'ok'}`
   */
  deleteExternal: async (id: string | number): Promise<{ message: 'ok' }> => {
    const response = await axios.delete(`/abm-audiences/external/${id}`);

    return response.data;
  },
  calculate: async (data: CalculateAbmReportParams): Promise<any> => {
    const response = await axios.post('/abm-reports/calculate', data);

    return response.data;
  },
  reports: async (data: ReportsAbmReportParams): Promise<any> => {
    const response = await axios.post('/abm-reports', data);

    return response.data;
  },
};
