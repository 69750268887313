import axios from 'services/api/axios';
import { ListItem } from '../types/lists';
import { Pagination } from '../types/pagination';
import { Region, RegionOption, RegionRequestData } from '../types/regions';

export default {
  getList: async (queryString?: string): Promise<Pagination<RegionOption>> => {
    const response = await axios.get(`/regions?${queryString}`);

    return response.data;
  },
  get: async (id: number, queryString?: string): Promise<Pagination<Region>> => {
    const response = await axios.get(`/lists/${id}/regions?${queryString}`);

    return response.data;
  },
  create: async (id: number, data: RegionRequestData): Promise<ListItem['id']> => {
    const response = await axios.post(`/lists/${id}/regions`, data);

    return response.data;
  },
  delete: async (id: number, queryString?: string): Promise<void> => {
    const response = await axios.delete(`/lists/${id}/regions?${queryString}`);

    return response.data;
  },
};
