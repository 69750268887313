import React from 'react';
// icons
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/table-checkbox-checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/icons/table-checkbox-unchecked.svg';
// styles
import { StyledCheckbox } from './styled';
// types
import { TableCheckboxProps } from './types';

const TableCheckbox: React.FC<TableCheckboxProps> = ({ checked, onChange }) => (
  <StyledCheckbox
    disableRipple
    checked={checked}
    onChange={onChange}
    icon={<CheckboxUncheckedIcon />}
    checkedIcon={<CheckboxCheckedIcon />}
  />
);

export default TableCheckbox;
