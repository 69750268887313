import axios from 'services/api/axios';
import { Pagination } from '../types/pagination';
import { PrebidAudience } from '../types/prebid-audiences';

export default {
  getList: async (queryString?: string): Promise<Pagination<PrebidAudience>> => {
    const response = await axios.get(`/audiences/prebid?${queryString}`);

    return response.data;
  },
};
