import { FC, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import moment from 'moment';
import { CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { metricsLabels } from 'pages/Analytics/DeepDataReports/Create/constants';
import { getMetricSymbol } from 'pages/Analytics/StandardReports/helpers';
import { formatNumber } from 'utils';
import { colors } from '../../constants';
import { LineChartWrapper, StyledLabel, StyledLineChart } from '../../styled';

type MetricByDimensionProps = {
  data: any[];
  metric: string;
  isLoading: boolean;
};

const Chart: FC<MetricByDimensionProps> = ({ data, isLoading, metric }) => {
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);

  const metricSymbol1 = getMetricSymbol(metric);

  const handleLegendClick = (event: any) => {
    setHiddenLines((prev) => {
      if (prev.includes(event.payload.name)) {
        return [...prev.filter((item) => item !== event.payload.name)];
      }

      return [...prev, event.payload.name];
    });
  };

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' height={450} alignItems='center'>
        <CircularProgress size={30} />
      </Box>
    );
  }
  return (
    <Box display='flex' flexDirection='column' justifyContent='center'>
      <Box display='flex' px={3}>
        <StyledLabel>
          {metricSymbol1 ? `${metricsLabels[metric]}, ${metricSymbol1}` : metricsLabels[metric]}
        </StyledLabel>
      </Box>

      <LineChartWrapper>
        <ResponsiveContainer width='100%' height='100%'>
          <StyledLineChart
            width={650}
            margin={{
              top: 15,
              right: 28,
              left: 28,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey='date'
              allowDuplicatedCategory={false}
              tickFormatter={(date) => moment(date).format('D MMM')}
              type='number'
              domain={['dataMin', 'dataMax']}
              interval={0}
              scale='time'
            />

            <YAxis
              tickCount={5}
              tickMargin={5}
              interval={0}
              axisLine={false}
              tickLine={false}
              type='number'
              domain={[0, 'dataMax']}
              tickFormatter={(value) => value.toFixed(2)}
            />

            <CartesianGrid
              verticalCoordinatesGenerator={(props) => [props.xAxis.x, props.xAxis.x + props.xAxis.width]}
            />

            <Tooltip
              labelFormatter={(date) => moment(date).format('D MMM YYYY')}
              formatter={(value: number, name) => [
                metric === 'ctr' ? `${value.toFixed(2)}${metricSymbol1}` : `${formatNumber(value)}${metricSymbol1}`,
                name,
              ]}
            />
            <Legend iconType='plainline' iconSize={20} onClick={handleLegendClick} />

            {data.map(([key, value], i) => {
              return (
                <Line
                  connectNulls
                  dataKey={metric}
                  data={value}
                  name={key}
                  key={`${key} ${metric}`}
                  stroke={colors[i]}
                  dot={{ fill: colors[i] }}
                  hide={hiddenLines.includes(key)}
                />
              );
            })}
          </StyledLineChart>
        </ResponsiveContainer>
      </LineChartWrapper>
    </Box>
  );
};

export default Chart;
