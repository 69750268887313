export const colors = [
  '#1f77b4', // Dark Blue
  '#ff7f0e', // Orange
  '#2ca02c', // Green
  '#d62728', // Red
  '#9467bd', // Purple
  '#8c564b', // Brown
  '#e377c2', // Pink
  '#7f7f7f', // Gray
  '#bcbd22', // Olive
  '#17becf', // Cyan
  '#1a55FF', // Royal Blue
  '#FF1A55', // Light Red
  '#55FF1A', // Light Green
  '#FFAA1A', // Light Orange
  '#FF1AA2', // Light Pink
  '#1AFFA2', // Light Cyan
  '#A21AFF', // Light Purple
  '#1A1AFF', // Dark Blue
  '#FF1A1A', // Dark Red
  '#1AFF1A', // Dark Green
  '#FFFF1A', // Yellow
  '#FF1AFF', // Magenta
  '#1AFFFF', // Aqua
  '#FF991A', // Dark Orange
  '#FF1A99', // Dark Pink
  '#991AFF', // Dark Purple
  '#1A99FF', // Light Blue
  '#FF991A', // Light Orange
  '#1A99FF', // Light Blue
  '#FF1A99', // Light Pink
  '#991AFF', // Light Purple

  '#00FFFF', // Cyan
  '#FFD700', // Gold
  '#7CFC00', // Lawn Green
  '#9932CC', // Dark Orchid
  '#FF6347', // Tomato
  '#00CED1', // Dark Turquoise
  '#FF1493', // Deep Pink
  '#FF4500', // Orange Red
  '#DA70D6', // Orchid
  '#48D1CC', // Medium Turquoise
  '#DC143C', // Crimson
  '#00FF7F', // Spring Green
  '#FF8C00', // Dark Orange
  '#6B8E23', // Olive Drab
  '#20B2AA', // Light Sea Green
  '#8A2BE2', // Blue Violet
  '#FF69B4', // Hot Pink
  '#6495ED', // Cornflower Blue
  '#DCDCDC', // Gainsboro
  '#ADFF2F', // Green Yellow
  '#9400D3', // Dark Violet
  '#FF00FF', // Magenta
  '#7FFFD4', // Aqua Marine
  '#BDB76B', // Dark Khaki
  '#FF6347', // Tomato
  '#00008B', // Dark Blue
  '#556B2F', // Dark Olive Green
  '#B22222', // Fire Brick
  '#A52A2A', // Brown
];
