// export const BASE_URL = 'https://api-v2.smadex.com';
// export const BASE_URL = 'https://sandbox.smadex.com';
export const BASE_URL = 'https://api.astrad.io';

export const AUTH_TOKEN_KEY = 'authToken';
export const STUDIO_APP_TOUR_KEY = 'isAppTourShow';

export const ALL_LISTS = 'allLists';
export const BUNDLES_LIST = 'bundle';
export const INVENTORIES_LIST = 'app';
export const IP_RANGES = 'ip_range';
export const GEOLOCATIONS = 'geo';

export enum OPTIONS_KEYS {
  EXCHANGES = 'exchanges',
  BUNDLE_DOMAINS = 'bundleDomains',
  DEALS = 'deals',
  INVENTORIES = 'inventories',
  IP_RANGES = 'ipRanges',
  CARRIERS = 'carriers',
  COUNTRIES = 'countries',
  CITIES = 'cities',
  GEO = 'geo',
  REGION = 'region',
  ZIPCODE = 'zipcode',
  DEVICE_MODELS = 'deviceModels',
  ANDROID_VERSIONS = 'androidVersions',
  IOS_VERSIONS = 'iosVersions',
  WINDOWS_VERSIONS = 'windowsVersions',
  ALL_AUDIENCES = 'allAudiences',
  CAMPAIGNS = 'campaigns',
  LINES = 'lines',
  TRACKING_GROUPS = 'trackingGroups',
  FOLDERS = 'folders',
  DEVICE_LANGUAGES = 'deviceLanguages',
  LINE_CREATIVES = 'lineCreatives',
  SEGMENTS = 'segments',
  ABM_AUDIENCES = 'abmAudiences',
}
