import axios from 'services/api/axios';
import { City } from '../types/cities';
import { AlternativePagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<AlternativePagination<City>> => {
    const response = await axios.get(queryString ? `/cities?${queryString}` : '/cities');

    return response.data;
  },
};
