import React from 'react';
// material-ui
import { Chip } from '@mui/material';
import { getStatusColor } from './helpers';
// types
import { StatusChipProps } from './types';

const StatusChip: React.FC<StatusChipProps> = ({ label, ...props }) => {
  return (
    <Chip
      {...props}
      label={label}
      size='small'
      color={getStatusColor(label.toLowerCase())}
      sx={{
        textTransform: 'capitalize',
      }}
    />
  );
};

export default StatusChip;
