import React, { memo } from 'react';
// material-ui
import { Box, Divider, Typography } from '@mui/material';
// styles
import { gray } from 'themes/colors';
// types
import { SectionTitleProps } from './types';

const SectionTitle: React.FC<SectionTitleProps> = ({ title, ...props }) => {
  return (
    <Box mt={0.25} mb={3} {...props}>
      <Typography fontSize={18} fontWeight={600} lineHeight='28px' mb={2.5}>
        {title}
      </Typography>

      <Divider sx={{ borderColor: gray[200] }} />
    </Box>
  );
};

export default memo(SectionTitle);
