import { FC, memo, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getOptionsConfig } from './helpers';
import { ListboxComponent } from './virtualizeAdapter';
import { ReactComponent as CancelCircleIcon } from 'assets/icons/cancel-circle.svg';
import { ReactComponent as CheckCircleBrokenIcon } from 'assets/icons/check-circle-broken.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/cross.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import {
  IconWrapper,
  Placeholder,
  StyledAutocompletePopper,
  StyledPopper,
  ToggleSelectButton,
  ValuesPreview,
} from './styled';
import { gray, primary } from 'themes/colors';
import { AutocompleteProps, Option, OptionValue, PopperComponentProps } from './types';

const PopperComponent: React.FC<PopperComponentProps> = ({ disablePortal, anchorEl, open, ...other }) => (
  <StyledAutocompletePopper {...other} />
);

const NewAutocomplete: FC<AutocompleteProps> = ({
  options,
  required,
  label,
  multiple,
  placeholder,
  value,
  error,
  loading,
  helperText,
  optionValue,
  maxWidth,
  maxDisplayValueStringLength,
  disabled,
  optionsKey,
  queryData,
  queryOptions,
  onChangeHelper,
  onChange,
  onClose,
  labelHint,
}) => {
  const displayValueBoxRef = useRef<HTMLDivElement>(null);

  const optionsConfig = useMemo(() => getOptionsConfig(optionsKey), [optionsKey]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data, isFetching } = useQuery<any>(
    [optionsKey, queryData],
    () => (optionsConfig ? optionsConfig.api(queryData) : null),
    {
      enabled: Boolean(optionsConfig) || queryOptions?.enabled,
      ...queryOptions,
    },
  );

  const fetchedOptions = useMemo(() => (data ? optionsConfig?.serializeOptions(data) : []), [data, optionsConfig]);

  const open = Boolean(anchorEl);

  const id = open ? 'autocomplete-multiple' : undefined;

  const currentOptions = optionsKey ? (fetchedOptions as Option[]) : (options as Option[]);

  const currentValue = useMemo(() => {
    if (optionValue) {
      return value as Option | Option[];
    }

    return multiple
      ? currentOptions.filter((option: Option) => (value as OptionValue[]).includes(option.value))
      : currentOptions.find((option: Option) => option.value === (value as OptionValue)) || null;
  }, [multiple, optionValue, currentOptions, value]);

  const handleChange = (event: SyntheticEvent<Element, Event>, option: Option | Option[] | null) => {
    if (optionValue) {
      onChange(option);
      onChangeHelper?.(option);
      return;
    }

    if (!optionValue && !multiple) {
      const value = (option as Option)?.value ?? null;

      onChange(value);
      onChangeHelper?.(value);
      return;
    }

    if (!optionValue && multiple) {
      const value = (option as Option[]).map((item) => item.value);

      onChange(value);
      onChangeHelper?.(value);
      return;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }

    onClose?.();

    setAnchorEl(null);
  }, [anchorEl, onClose]);

  const handleSelectAllClick = useCallback(() => {
    if (optionValue) {
      onChange(currentOptions);
      onChangeHelper?.(currentOptions);
    } else {
      const values = currentOptions.map((item) => item.value);
      onChange(values);
      onChangeHelper?.(values);
    }
  }, [optionValue, onChange, currentOptions, onChangeHelper]);

  const handleSelectNoneClick = useCallback(() => {
    onChange([]);
    onChangeHelper?.([]);
  }, [onChange, onChangeHelper]);

  const displayValue = useMemo(() => {
    let preparedValue = multiple ? (currentValue as Option[])?.[0]?.label : (currentValue as Option)?.label;

    if (preparedValue?.length > (maxDisplayValueStringLength ?? 15)) {
      preparedValue = preparedValue.slice(0, maxDisplayValueStringLength ?? 15) + '...';
    }

    if (multiple) {
      if ((currentValue as Option[])?.length === 0) {
        return null;
      }

      if ((currentValue as Option[])?.length === 1) {
        return `${preparedValue}`;
      }

      if ((currentValue as Option[])?.length > 1) {
        return `${preparedValue}, ... (Total: ${(currentValue as Option[]).length})`;
      }
    } else {
      return preparedValue;
    }
  }, [currentValue, maxDisplayValueStringLength, multiple]);

  return (
    <>
      <Box display='flex' width='100%' maxWidth={maxWidth} ref={displayValueBoxRef}>
        <FormControl fullWidth>
          {!!label && (
            <Box display='flex' alignItems='center' gap={0.5}>
              <FormLabel required={required}>{label}</FormLabel>
              {labelHint ? (
                <span>
                  <Tooltip title={labelHint}>
                    <HelpIcon width={18} height={18} />
                  </Tooltip>
                </span>
              ) : null}
            </Box>
          )}

          <ValuesPreview
            disabled={Boolean(disabled)}
            aria-describedby={id}
            onClick={disabled ? undefined : handleClick}
          >
            {displayValue ? displayValue : <Placeholder>{placeholder}</Placeholder>}
            <IconWrapper open={open}>
              {loading || isFetching ? <CircularProgress size={16} /> : <ChevronDownIcon />}
            </IconWrapper>
          </ValuesPreview>

          {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
        </FormControl>
      </Box>

      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        maxWidth={maxWidth || displayValueBoxRef.current?.getBoundingClientRect().width}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            {multiple && (
              <Stack direction='row' gap={2} mb={2}>
                <ToggleSelectButton
                  disabled={(currentValue as Option[]).length === currentOptions?.length}
                  variant='contained'
                  disableRipple
                  onClick={handleSelectAllClick}
                >
                  <CheckCircleBrokenIcon /> {'Select all'}
                </ToggleSelectButton>

                <ToggleSelectButton
                  disabled={(currentValue as Option[]).length === 0}
                  variant='contained'
                  disableRipple
                  onClick={handleSelectNoneClick}
                >
                  <CancelCircleIcon width={20} height={20} fill={primary[700]} /> {'Select none'}
                </ToggleSelectButton>
              </Stack>
            )}

            <Autocomplete
              open
              placeholder={placeholder ?? undefined}
              limitTags={1}
              disablePortal
              fullWidth
              value={currentValue}
              multiple={multiple}
              options={currentOptions}
              onChange={handleChange}
              popupIcon={<ChevronDownIcon />}
              ChipProps={{ deleteIcon: <DeleteIcon /> }}
              disableCloseOnSelect={multiple}
              getOptionLabel={(option) => String(option.label)}
              PopperComponent={PopperComponent}
              noOptionsText='No values'
              loadingText='Loading...'
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  fullWidth
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder='Search'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon width={20} height={20} fill={gray[500]} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              ListboxComponent={ListboxComponent}
              renderOption={(props, option, { selected }) => [props, option, selected, multiple] as React.ReactNode}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default memo(NewAutocomplete);
