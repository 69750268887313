import axios from 'services/api/axios';
import { Device } from '../types/devices';
import { Pagination } from '../types/pagination';

export default {
  getList: async (): Promise<Pagination<Device>> => {
    const response = await axios.get('/devices?$limit=23');

    return response.data;
  },
};
