import React, { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
// material-ui
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { fromEvent } from 'file-selector';
// utils
import { convertBase64 } from 'utils';
// icons
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
// styles
import { DropzoneContainer, StyledLoader } from './styled';
import { primary } from 'themes/colors';
// types
import { DragAndDropProps } from './types';

const DragAndDropZone: React.FC<DragAndDropProps> = ({
  handleDrop,
  acceptImages,
  acceptCSV,
  acceptVideos,
  disableImgSizeRestriction,
  title,
  subtitle,
  multiple = true,
  allowedSizeImage = ['320x50', '320x480', '300x250', '728x90', '480x320', '768x1024', '1024x768'],
  isLoading,
  squareImage,
  maxSize = 100000000,
}) => {
  const { t } = useTranslation('common');
  const [error, setError] = useState('');

  const onDrop = useCallback(
    (files: File[], fileRejections: any) => {
      if (fileRejections.length) {
        let errorMessage = fileRejections[0].errors[0].message;

        const isTooLarge = fileRejections[0].errors[0].code === 'file-too-large';
        const isInvalidType = fileRejections[0].errors[0].code === 'file-invalid-type';

        if (isTooLarge) {
          errorMessage = t('File is too large');
        }

        if (isInvalidType) {
          errorMessage = t(`Invalid file type. Please choose a file with ${acceptCSV ? '.csv' : 'image'} format`);
        }

        setError(errorMessage);
      } else {
        setError('');
        handleDrop && handleDrop(files);
      }
    },
    [acceptCSV, handleDrop, t],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      ...(acceptCSV && { 'text/csv': ['.csv'] }),
      ...(acceptImages && { 'image/png': [], 'image/jpg': [], 'image/jpeg': [], 'image/gif': [] }),
      ...(acceptVideos && { 'video/mp4': ['.mp4', '.MP4'] }),
    },
    maxSize,
    multiple,
    getFilesFromEvent: async (event) => {
      if (acceptCSV || acceptVideos) {
        return await fromEvent(event);
      }

      const files = await fromEvent(event);
      const promises = [];

      for (let index = 0; index < files.length; index++) {
        const file: any = files[index];
        const imageSrc = file && ((await convertBase64(file)) as string);

        const promise = new Promise<any>((resolve, reject) => {
          const image = new Image();

          image.onload = function () {
            file.imageSize = `${image.width}x${image.height}`;

            resolve(file);
          };

          image.src = imageSrc;
        });

        promises.push(promise);
      }

      return await Promise.all(promises);
    },
    validator: (file) => {
      if (acceptCSV || acceptVideos) {
        return null;
      }

      if (!allowedSizeImage.includes((file as any).imageSize) && !disableImgSizeRestriction) {
        return {
          code: 'size-no-valid',
          message: `Allowed sizes are: ${allowedSizeImage.join(', ')}`,
        };
      }

      if (squareImage) {
        const [width, height] = (file as any).imageSize.split('x');

        const isSquare = width === height;

        if (!isSquare) {
          return {
            code: 'square-image-only',
            message: 'Only square image',
          };
        }
      }

      return null;
    },
  });

  return (
    <div className='container' style={{ position: 'relative' }}>
      {isLoading && <StyledLoader title='Uploading files...' />}

      <DropzoneContainer {...getRootProps({ className: 'dropzone' })}>
        <UploadIcon />

        <input {...getInputProps()} />

        <Typography fontSize='14px' sx={{ mt: 1 }}>
          <Box component='span' sx={{ fontWeight: 500, color: primary[700] }}>
            {t('Click to upload')}
          </Box>
          &nbsp;{t('or drag and drop')}
        </Typography>

        {title && <Typography fontSize='12px'>{title}</Typography>}

        {subtitle && <Typography fontSize='12px'>{subtitle}</Typography>}
      </DropzoneContainer>

      {error && (
        <Alert severity='error' sx={{ mt: 2 }}>
          <>
            <AlertTitle>Error</AlertTitle>
            {error}
          </>
        </Alert>
      )}
    </div>
  );
};

export default memo(DragAndDropZone);
