import axios from 'services/api/axios';
import { OSVersion } from '../types/os-versions';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<OSVersion>> => {
    const response = await axios.get(`/os-versions?${queryString}`);

    return response.data;
  },
};
