import axios from 'services/api/axios';
import { Pagination } from '../types/pagination';
import { TrackingGroup, TrackingGroupRequestData } from '../types/tracking-groups';

export default {
  getList: async (queryString?: string): Promise<Pagination<TrackingGroup>> => {
    const response = await axios.get(queryString ? `/tracking-groups?${queryString}` : '/tracking-groups');

    return response.data;
  },
  get: async (id: TrackingGroup['id']): Promise<TrackingGroup> => {
    const response = await axios.get(`/tracking-groups/${id}`);

    return response.data;
  },
  create: async (data: TrackingGroupRequestData): Promise<{ id: TrackingGroup['id'] }> => {
    const response = await axios.post('/tracking-groups', data);

    return response.data;
  },
  update: async (
    id: TrackingGroup['id'],
    data: Omit<TrackingGroupRequestData, 'accountId'>,
  ): Promise<TrackingGroup['id']> => {
    const response = await axios.patch(`/tracking-groups/${id}`, data);

    return response.data;
  },
  delete: async (id: TrackingGroup['id']): Promise<void> => {
    const response = await axios.delete(`/tracking-groups/${id}`);

    return response.data;
  },
};
