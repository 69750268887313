import axios from 'axios';
import { BASE_URL } from 'services/constants';
import { clearAuthToken, getAuthToken } from 'services/storage/auth';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const certisDspAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CERTIS_DSP_BASE_URL,
});

certisDspAxiosInstance.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();

    if (authToken) {
      config.headers.authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// api for statistics campaigns & lines
export const selfserviceAxiosInstance = axios.create({
  baseURL: 'https://selfservice.api.astrad.io',
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();

    if (authToken) {
      config.headers.authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      clearAuthToken();
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
