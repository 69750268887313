import axios from 'services/api/axios';
import { EventsMappings, EventsMappingsRequestData } from '../types/events-mappings';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<EventsMappings>> => {
    const response = await axios.get(`/events-mappings?${queryString}`);

    return response.data;
  },
  get: async (id: EventsMappings['id']): Promise<EventsMappings> => {
    const response = await axios.get(`/events-mappings/${id}`);

    return response.data;
  },
  create: async (data: EventsMappingsRequestData): Promise<EventsMappings['id']> => {
    const response = await axios.post('/events-mappings', data);

    return response.data;
  },
  update: async (
    id: EventsMappings['id'],
    data: Omit<EventsMappingsRequestData, 'accountId'>,
  ): Promise<EventsMappings['id']> => {
    const response = await axios.patch(`/events-mappings/${id}`, data);

    return response.data;
  },
  delete: async (id: EventsMappings['id']): Promise<void> => {
    const response = await axios.delete(`/events-mappings/${id}`);

    return response.data;
  },
};
