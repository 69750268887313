import { FC } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import NewAutocomplete from 'components/NewAutocomplete';
import { gray } from 'themes/colors';

export type FiltersState = {
  domainList: string[];
};

type FiltersProps = {
  isOpen: boolean;
  domainsList: string[];
  filters: FiltersState;
  onChange: (data: FiltersState) => void;
};

const Filters: FC<FiltersProps> = ({ isOpen, domainsList, filters, onChange }) => {
  return (
    <Collapse in={isOpen} unmountOnExit>
      <Box sx={{ p: '16px', pb: '24px', backgroundColor: gray[100], borderRadius: '6px' }}>
        <Typography variant='h6' mb={2}>
          Filters
        </Typography>

        <Box>
          <NewAutocomplete
            multiple
            label='Domain list'
            value={filters.domainList}
            onChange={(value) => onChange({ ...filters, domainList: value as string[] })}
            options={domainsList.map((item) => ({ label: item, value: item }))}
          />
        </Box>
      </Box>
    </Collapse>
  );
};

export default Filters;
