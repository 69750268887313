import { FC, useRef, useState } from 'react';
import { Box, Button, ButtonGroup, ClickAwayListener, Popper } from '@mui/material';
import { ReactComponent as SortArrowDownIcon } from 'assets/icons/sort-arrow-down.svg';

const options = ['Download CSV'];

type ComboButtonProps = {
  onPdfDownload: () => void;
  onCSVDownload: () => void;
  disabled: boolean;
};

const ComboButton: FC<ComboButtonProps> = ({ onPdfDownload, onCSVDownload, disabled }) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const handleMenuItemClick = () => {
    onCSVDownload();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    onPdfDownload();
  };

  return (
    <>
      <ButtonGroup
        disableRipple
        disableFocusRipple
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
        sx={{ boxShadow: 'none' }}
      >
        <Button disabled={disabled} onClick={handleClick}>
          Download PDF
        </Button>
        <Button
          disabled={disabled}
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <SortArrowDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box display='flex' flexDirection='column' width={200}>
            {options.map((option) => (
              <Button disabled={disabled} key={option} onClick={handleMenuItemClick} variant='outlined'>
                {option}
              </Button>
            ))}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default ComboButton;
