import axios, { certisDspAxiosInstance } from 'services/api/axios';
import {
  CloneCreativeRequestBody,
  CreateCreativeData,
  Creative,
  RecommendedBidItem,
  UpdateCreativeData,
} from '../types/creatives';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Creative>> => {
    const response = await axios.get(queryString ? `/creatives?${queryString}` : '/creatives');

    return response.data;
  },
  get: async (id: Creative['id']): Promise<Creative> => {
    const response = await axios.get(`/creatives/${id}`);

    return response.data;
  },
  getRecommendedBit: async (queryString: string): Promise<RecommendedBidItem[]> => {
    const response = await certisDspAxiosInstance.get(`/bids/best?${queryString}`);

    return response.data;
  },
  create: async (data: CreateCreativeData) => {
    const response = await axios.post('/creatives', data);

    return response.data;
  },
  clone: async (id: number | string, data: CloneCreativeRequestBody) => {
    const response = await axios.post(`/creatives/${id}/clone`, data);

    return response.data;
  },
  update: async (id: Creative['id'], data: UpdateCreativeData) => {
    const response = await axios.patch(`/creatives/${id}`, data);

    return response.data;
  },
  uploadVideo: async (data: FormData) => {
    const response = await axios.post('/creatives/video-upload', data);

    return response.data;
  },
};
