export enum ReportStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum ReportType {
  STANDARD = 'STANDARD',
  DEEP_DATA = 'DEEP_DATA',
}

export type RequestedParams = {
  dimensions: string[];
  startDate: string | Date;
  endDate: string | Date;
  fieldsSortingType?: number;
  filters?: string;
  having?: string;
  limit?: number;
  metrics: string[];
  rollUp: string;
  sort?: [{ field: string; asc: boolean }] | [];
};

export type ReportFile = {
  id: number;
  name: string;
  title: string;
  documentType: 'REPORT_FILE';
  downloadUrl: string;
};

export type Report = {
  id: number;
  type: ReportType;
  name: string;
  createdAt: string;
  completedAt: string;
  requestedParams: RequestedParams;
  status: ReportStatus;
  result: [null];
  reportFile: ReportFile;
  displayTable: {
    show: string[];
  };
  displayChart: {
    show: string[];
  };
};

export type CreateReportRequestBody = Omit<
  Report,
  'id' | 'status' | 'result' | 'reportFile' | 'createdAt' | 'completedAt'
>;

export type CalculateReportParams = {
  dimensions: string[];
  startDate: string | Date;
  endDate: string | Date;
  metrics: string[];
  rollUp: string;
  filters?: any;
  displayTable: {
    show: string[];
  };
  displayChart: {
    show: string[];
  };
};
