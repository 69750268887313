import axios from 'services/api/axios';
import { ActivityAudience, ActivityAudiencesRequestData } from '../types/activity-audiences';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<ActivityAudience>> => {
    const response = await axios.get(`/audiences/activity?${queryString}`);

    return response.data;
  },
  get: async (id: ActivityAudience['id']): Promise<ActivityAudience> => {
    const response = await axios.get(`/audiences/activity/${id}`);

    return response.data;
  },
  create: async (data: ActivityAudiencesRequestData): Promise<ActivityAudience['id']> => {
    const response = await axios.post('/audiences/activity', data);

    return response.data;
  },
  update: async (
    id: ActivityAudience['id'],
    data: Omit<ActivityAudiencesRequestData, 'accountId' | 'description'>,
  ): Promise<ActivityAudience['id']> => {
    const response = await axios.put(`/audiences/activity/${id}`, data);

    return response.data;
  },
};
