// material-ui
import { Box, styled, Typography } from '@mui/material';
// libs
import { LineChart as RechartsLineChart } from 'recharts';
// styles
import { gray } from 'themes/colors';

export const Wrapper = styled(Box)`
  background-color: #fff;
  padding: 32px 8px 40px 0;
  margin: 4px 0;
`;

export const LineChartWrapper = styled(Box)`
  width: 100%;
  height: 450px;
`;

export const StyledLineChart = styled(RechartsLineChart)`
  & .recharts-cartesian-axis-line,
  & .recharts-cartesian-axis-tick-line,
  & .recharts-cartesian-grid-horizontal line,
  & .recharts-cartesian-grid-vertical line {
    stroke: ${gray[200]};
  }

  & .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    fill: ${gray[500]};
  }

  & .recharts-line-curve {
    stroke-width: 2px;
  }

  & .recharts-legend-item {
    margin-right: 14px !important;
  }

  & .recharts-legend-item-text {
    display: inline-flex;
    font-size: 16px;
    line-height: 20px;
    color: ${gray[500]} !important;
    margin-left: 4px;
  }

  & .recharts-label {
    font-size: 12px;
    font-weight: 600;
    fill: ${gray[500]};
  }
`;

export const StyledLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  color: ${gray[500]};
`;
