import { certisDspAxiosInstance } from 'services/api/axios';
import { UserProfileSettings } from '../types/user-profile-settings.ts';

export default {
  getUserProfileSetting: async (): Promise<UserProfileSettings> => {
    const response = await certisDspAxiosInstance.get('/users/me');

    return response.data;
  },
  updateMaxBid: async (maxBid: number | null): Promise<UserProfileSettings> => {
    const response = await certisDspAxiosInstance.put('/users/me', { userProfile: { maxBid } });

    return response.data;
  },
};
