import { FC, memo, useCallback } from 'react';
import { useField } from 'formik';
import NewAutocomplete from 'components/NewAutocomplete';
import { AutocompleteValue } from 'components/NewAutocomplete/types';
import { NewAutocompleteFieldProps } from './types';

const NewAutocompleteField: FC<NewAutocompleteFieldProps> = ({ name, multiple, ...rest }) => {
  const [, meta, helpers] = useField({ name, multiple });

  const { value } = meta;

  const { setValue } = helpers;

  const handleChange = useCallback(
    (option: AutocompleteValue) => {
      setValue(option, true);
    },
    [setValue],
  );

  return (
    <NewAutocomplete
      {...rest}
      error={Boolean(meta.error)}
      helperText={meta.error}
      multiple={multiple}
      value={value}
      onChange={handleChange}
    />
  );
};

export default memo(NewAutocompleteField);
