import React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { StyledOptionItem, TextWrapper } from 'components/NewAutocomplete/styled';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  return dataSet[3] && !dataSet[4] ? (
    <StyledOptionItem {...dataSet[0]} style={inlineStyle}>
      <TextWrapper>{dataSet[1].label}</TextWrapper> {dataSet[2] ? <CheckIcon /> : null}
    </StyledOptionItem>
  ) : (
    <li {...dataSet[0]} style={inlineStyle}>
      {dataSet[1].label}
    </li>
  );
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;

    const itemSize = 48;

    const getChildSize = () => {
      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <div>
            <VariableSizeList
              itemData={itemData}
              height={getHeight() + 2}
              width='100%'
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType='ul'
              itemSize={() => getChildSize()}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </div>
        </OuterElementContext.Provider>
      </div>
    );
  },
);
