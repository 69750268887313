import axios from 'services/api/axios';
import { IpRange, IpRangeRequestData } from '../types/ip-ranges';
import { ListItem } from '../types/lists';
import { Pagination } from '../types/pagination';

export default {
  get: async (id: number, queryString?: string): Promise<Pagination<IpRange>> => {
    const response = await axios.get(`/lists/${id}/ip-ranges?${queryString}`);

    return response.data;
  },
  create: async (id: ListItem['id'], data: IpRangeRequestData): Promise<ListItem['id']> => {
    const response = await axios.post(`/lists/${id}/ip-ranges`, data);

    return response.data;
  },
  // createFromCSV: async (id: ListItem['id'], data: BundleCSVRequestData): Promise<BundleResponseData> => {
  //   const response = await axios.post(`/lists/${id}/ip-ranges-upload/csv`, data);

  //   return response.data;
  // },
  delete: async (id: number, queryString?: string): Promise<void> => {
    const response = await axios.delete(`/lists/${id}/ip-ranges?${queryString}`);

    return response.data;
  },
};
