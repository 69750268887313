import { useFormikContext } from 'formik';
import moment from 'moment';

export enum SideBarItemID {
  CURRENT_MONTH = 1,
  TODAY,
  YESTERDAY,
  LAST_MONTH,
  THIS_WEEK,
  LAST_7_DAYS,
  LAST_30_DAYS,
}

export const useSideBarItems = ({ startDateName, endDateName }: { startDateName: string; endDateName: string }) => {
  const { setFieldValue } = useFormikContext();

  const todaysDate = moment().toDate();
  const startOfCurrentMonthDate = moment().startOf('month').toDate();
  const yesterdayDate = moment().subtract(1, 'day').toDate();
  const startOfPrevMonthDate = moment().subtract(1, 'month').startOf('month').toDate();
  const endOfPrevMonthDate = moment().subtract(1, 'month').endOf('month').toDate();
  const startOfWeek = moment().startOf('week').toDate();
  const pastSevenDays = moment().subtract(6, 'day').toDate();
  const pastThirtyDays = moment().subtract(30, 'day').toDate();

  const handleSetDates = (startDate: Date, endDate: Date) => () => {
    setFieldValue(startDateName, startDate);
    setFieldValue(endDateName, endDate);
  };

  return [
    {
      id: SideBarItemID.CURRENT_MONTH,
      title: 'Current month',
      onClick: handleSetDates(startOfCurrentMonthDate, todaysDate),
      startDate: startOfCurrentMonthDate,
      endDate: todaysDate,
    },
    {
      id: SideBarItemID.TODAY,
      title: 'Today',
      onClick: handleSetDates(todaysDate, todaysDate),
      startDate: todaysDate,
      endDate: todaysDate,
    },
    {
      id: SideBarItemID.YESTERDAY,
      title: 'Yesterday',
      onClick: handleSetDates(yesterdayDate, yesterdayDate),
      startDate: yesterdayDate,
      endDate: yesterdayDate,
    },
    {
      id: SideBarItemID.LAST_MONTH,
      title: 'Last Month',
      onClick: handleSetDates(startOfPrevMonthDate, endOfPrevMonthDate),
      startDate: startOfPrevMonthDate,
      endDate: endOfPrevMonthDate,
    },
    {
      id: SideBarItemID.THIS_WEEK,
      title: 'This week',
      onClick: handleSetDates(startOfWeek, todaysDate),
      startDate: startOfWeek,
      endDate: todaysDate,
    },
    {
      id: SideBarItemID.LAST_7_DAYS,
      title: 'Last 7 days',
      onClick: handleSetDates(pastSevenDays, todaysDate),
      startDate: pastSevenDays,
      endDate: todaysDate,
    },
    {
      id: SideBarItemID.LAST_30_DAYS,
      title: 'Last 30 days',
      onClick: handleSetDates(pastThirtyDays, todaysDate),
      startDate: pastThirtyDays,
      endDate: todaysDate,
    },
  ];
};
