import React from 'react';
// styles
import { StyledSimpleBar } from './styled';
// types
import { ScrollableContentProps } from './types';

import 'simplebar-react/dist/simplebar.min.css';

const ScrollableContent: React.FC<ScrollableContentProps> = ({ children, ...props }) => {
  return <StyledSimpleBar {...props}>{children}</StyledSimpleBar>;
};

export default ScrollableContent;
