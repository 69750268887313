import { Box, IconButton, Typography } from '@mui/material';
import usePaginationRange from 'hooks/usePaginationRange';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { TablePaginationProps } from '../TablePagination/types';

const MobileTablePagination: React.FC<TablePaginationProps> = ({ table }) => {
  const totalPages = table.getPageCount();

  const paginationRange = usePaginationRange({
    currentPage: table.getState().pagination.pageIndex + 1,
    pages: totalPages,
  });

  if (!paginationRange || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  return (
    <Box display='flex' justifyContent='space-between'>
      <IconButton onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} disableRipple>
        <ArrowLeftIcon />
      </IconButton>

      <Typography fontSize={14}>
        Page {table.getState().pagination.pageIndex + 1} of {table.getState().pagination.pageSize}
      </Typography>

      <IconButton onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} disableRipple>
        <ArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export default MobileTablePagination;
