import { FC, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { ActionButton, TabPanel, Tabs } from 'components';
import { getDomainCampaignColumns, getDomainColumns, getUnGroupedColumns, tabs } from './constants';
import ReportTable from './ReportTable';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { FormData } from '../Filter/types';

type TableTabsProps = {
  filterData: FormData;
};

const TableTabs: FC<TableTabsProps> = ({ filterData }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, tab: number) => {
    setCurrentTab(tab);
  }, []);

  return (
    <Box mt={2}>
      <Box display='flex' flexDirection='column' gap={2}>
        <Box display='flex' justifyContent='space-between' alignItems='center' gap={3}>
          <Box width='100%'>
            <Tabs tabs={tabs} activeTab={currentTab} handleChange={handleChangeTab} sx={{ margin: 0 }} />
          </Box>

          <ActionButton isActive={isFilterOpen} onClick={() => setIsFilterOpen(!isFilterOpen)}>
            <FilterIcon />
          </ActionButton>
        </Box>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <ReportTable filterData={filterData} getColumns={getUnGroupedColumns} isFilterOpen={isFilterOpen} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <ReportTable
          filterData={filterData}
          getColumns={getDomainColumns}
          groupBy={['domainName']}
          isFilterOpen={isFilterOpen}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <ReportTable
          filterData={filterData}
          getColumns={getDomainCampaignColumns}
          groupBy={['domainName', 'campaignName']}
          isFilterOpen={isFilterOpen}
        />
      </TabPanel>
    </Box>
  );
};

export default TableTabs;
