/* eslint-disable camelcase */
export const metricsLabels: { [key: string]: string } = {
  bids: 'Bids',
  impressions: 'Impressions',
  clicks: 'Clicks',
  buys: 'Buys',
  registrations: 'Registrations',
  subscriptions: 'Subscriptions',
  funnel_step_1: 'Funnel step 1',
  funnel_step_2: 'Funnel step 2',
  funnel_step_3: 'Funnel step 3',
  installs: 'Installs',
  landings: 'Landings',
  views: 'Views',
  first_quartile_views: 'First quartile views',
  mid_point_views: 'Mid point views',
  third_quartile_views: 'Third quartile views',
  completed_views: 'Completed views',
  other_conversions: 'Other conversions',
  media_spend: 'Media spend',
  third_party_spend: 'Third party spend',
  total_spend: 'Total spend',
  cpc: 'CPC',
  cpm: 'CPM',
  cp_buys: 'CP buys',
  cp_funnel_step_1: 'CP funnel step 1',
  cp_funnel_step_2: 'CP funnel step 2',
  cp_funnel_step_3: 'CP funnel step 3',
  cp_installs: 'CP installs',
  cp_landings: 'CP landings',
  cp_registrations: 'CP registrations',
  cp_subscriptions: 'CP subscriptions',
  cp_views: 'CP views',
  cp_first_quartile_views: 'CP first quartile views',
  cp_mid_point_views: 'CP mid point views',
  cp_third_quartile_views: 'CP third quartile views',
  cp_completed_views: 'CP completed views',
  cp_skips: 'CP skips',
  cp_other_conversions: 'CP other conversions',
  clear_rate: 'Clear rate',
  ctr: 'CTR',
  buys_rate: 'Buys rate',
  funnel_step_1_rate: 'Funnel step 1 rate',
  funnel_step_2_rate: 'Funnel step 2 rate',
  funnel_step_3_rate: 'Funnel step 3 rate',
  installs_rate: 'Installs rate',
  landings_rate: 'Landings rate',
  registrations_rate: 'Registrations rate',
  subscriptions_rate: 'Subscriptions rate',
  views_rate: 'Views rate',
  first_quartile_views_rate: 'First quartile views rate',
  mid_point_views_rate: 'Mid point views rate',
  third_quartile_views_rate: 'Third quartile views rate',
  completed_views_rate: 'Completed views rate',
  skips_rate: 'Skips rate',
  other_conversions_rate: 'Other conversions rate',
};

export const dimensionsLabel: { [key: string]: string } = {
  date_time: 'Date time',
  account_id: 'Account id',
  account_name: 'Account name',
  ad_size: 'Ad size',
  advertiser_bundle: 'Advertiser bundle',
  advertiser_domain: 'Advertiser domain',
  agency_id: 'Agency id',
  agency_name: 'Agency name',
  app_name: 'App name',
  bundle: 'Bundle',
  campaign_id: 'Campaign id',
  campaign_name: 'Campaign name',
  carrier: 'Carrier',
  connection_type: 'Connection type',
  country: 'Country',
  creative_id: 'Creative id',
  creative_name: 'Creative name',
  creative_size: 'Creative size',
  creative_type: 'Creative type',
  creative_url: 'Creative url',
  currency: 'Currency',
  device_os: 'Device os',
  exchange_id: 'Exchange id',
  exchange_name: 'Exchange name',
  inventory_bundle: 'Inventory bundle',
  inventory_domain: 'Inventory domain',
  inventory_hash: 'Inventory hash',
  inventory_id: 'Inventory id',
  inventory_name: 'Inventory name',
  inventory_tag_id: 'Inventory tag id',
  inventory_type: 'Inventory type',
  line_creative_id: 'Line creative id',
  line_id: 'Line id',
  line_name: 'Line name',
};
