import React from 'react';
// libs
import { NumericFormat } from 'react-number-format';
// components
import Input from '../Input';
// types
import { InputProps } from '../types';

/**
 * Use this component to format numbers with commas and decimals.
 * See https://s-yadav.github.io/react-number-format/docs/numeric_format
 */

const NumericInput: React.FC<InputProps & { step?: number }> = ({ field, form, step, ...rest }) => {
  return (
    <NumericFormat
      {...field}
      field={field}
      form={form}
      thousandsGroupStyle='thousand'
      thousandSeparator=','
      decimalSeparator='.'
      customInput={Input}
      allowNegative={false}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowUp' && step) {
          e.preventDefault();

          form.setFieldValue(
            field.name,
            step < 1 ? (Number(field.value) + step).toFixed(1) : Number(field.value) + step,
          );
        }
        if (e.key === 'ArrowDown' && step && Number(field.value) !== 0) {
          e.preventDefault();

          form.setFieldValue(
            field.name,
            step < 1 ? (Number(field.value) - step).toFixed(1) : Number(field.value) - step,
          );
        }
      }}
      {...rest}
    />
  );
};

export default NumericInput;
