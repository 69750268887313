import axios from 'services/api/axios';
import { Agency, PatchAgencyRequestBody } from '../types/agencies';

export default {
  getOne: async (id: number | string): Promise<Agency> => {
    const response = await axios.get(`/agencies/${id}`);

    return response.data;
  },
  updateOne: async (id: number | string, data: PatchAgencyRequestBody): Promise<Agency> => {
    const response = await axios.patch(`/agencies/${id}`, data);

    return response.data;
  },
};
