import axios from 'services/api/axios';
import { ListItem, ListItemLine, ListItemRequestData, ListItemUpdateRequestBody } from '../types/lists';
import { Pagination } from '../types/pagination';

export default {
  getLists: async (queryString?: string): Promise<Pagination<ListItem>> => {
    const response = await axios.get(`/lists?${queryString}`);

    return response.data;
  },
  get: async (id: number): Promise<ListItem> => {
    const response = await axios.get(`/lists/${id}`);

    return response.data;
  },
  getLines: async (id: number): Promise<Pagination<ListItemLine>> => {
    const response = await axios.get(`/lists/${id}/lines`);

    return response.data;
  },
  create: async (data: ListItemRequestData): Promise<ListItem> => {
    const response = await axios.post('/lists', data);

    return response.data;
  },
  delete: async (id: ListItem['id']): Promise<void> => {
    const response = await axios.delete(`/lists/${id}`);

    return response.data;
  },
  update: async (id: number | string, data: ListItemUpdateRequestBody): Promise<ListItem> => {
    const response = await axios.patch(`/lists/${id}`, data);

    return response.data;
  },
};
