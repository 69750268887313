import React, { memo } from 'react';
// material-ui
import { FormControl, FormLabel, InputAdornment, TextField } from '@mui/material';
// libs
import { getIn } from 'formik';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
// types
import { InputProps } from './../types';

const Input: React.FC<InputProps> = ({
  field,
  label,
  inputProps,
  InputProps,
  helperText,
  type = 'text',
  form: { errors, touched },
  required,
  ignoreTouchedToShowError,
  sx,
  ...props
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = ignoreTouchedToShowError ? !!errorMessage : !!(errorMessage && getIn(touched, field.name));

  return (
    <FormControl fullWidth sx={sx}>
      {label && <FormLabel required={required}>{label}</FormLabel>}

      <TextField
        {...field}
        type={type}
        variant='outlined'
        value={field.value ?? ''}
        InputProps={{
          endAdornment: isError && (
            <InputAdornment position='end'>
              <ErrorIcon />
            </InputAdornment>
          ),
          ...(InputProps as Record<string, unknown>),
        }}
        inputProps={{
          min: 0,
          autoComplete: 'new-password',
          form: {
            autoComplete: 'off',
          },
          ...inputProps,
        }}
        error={isError}
        helperText={isError ? errorMessage : helperText}
        {...props}
      />
    </FormControl>
  );
};

export default memo(Input);
