import axios from 'services/api/axios';
import { AbTest, AbTestRequestData } from '../types/ab-tests';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<AbTest>> => {
    const response = await axios.get(`/ab-tests?${queryString}`);

    return response.data;
  },
  get: async (id: AbTest['id']): Promise<AbTest> => {
    const response = await axios.get(`/ab-tests/${id}`);

    return response.data;
  },
  create: async (data: Omit<AbTestRequestData, 'lineTests'>): Promise<{ id: AbTest['id'] }> => {
    const response = await axios.post('/ab-tests', data);

    return response.data;
  },
  update: async (id: AbTest['id'], data: Partial<Omit<AbTestRequestData, 'accountId'>>): Promise<AbTest['id']> => {
    const response = await axios.patch(`/ab-tests/${id}`, data);

    return response.data;
  },
  delete: async (id: AbTest['id']): Promise<void> => {
    const response = await axios.delete(`/ab-tests/${id}`);

    return response.data;
  },
};
