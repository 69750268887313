import axios from 'services/api/axios';
import { AllAudiences } from '../types/all-audiences';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<AllAudiences>> => {
    const response = await axios.get(queryString ? `/all-audiences?${queryString}` : '/all-audiences');

    return response.data;
  },
};
