import { Button, Popper, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { gray, primary } from 'themes/colors';
import { shadows } from 'themes/shadows';

export const Placeholder = styled(Typography)`
  font-weight: 500;
  color: ${gray[500]};
`;

export const ValuesPreview = styled('div')`
  width: 100%;
  display: 'flex';
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${gray[300]};
  box-shadow: ${shadows.xs};
  position: relative;
  gap: 8px;
  height: 44px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${gray[900]};
  cursor: pointer;

  ${(props: { disabled: boolean }) =>
    props.disabled
      ? `
    background-color: ${gray[100]};
    color: ${gray[500]};
    cursor: default;
  `
      : ''}

  &:hover {
    border-color: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : primary[300])};
  }
`;

export const TextWrapper = styled('span')`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 25px;
`;

export const StyledOptionItem = styled('li')`
  gap: 8px;
  padding: 10px 14px;
  color: ${gray[900]};
  justify-content: space-between;
  position: relative;

  & > svg {
    position: absolute;
    right: 14px;
    top: auto;
  }

  &&.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    background-color: ${gray[50]};
  }
`;

export const ToggleSelectButton = styled(Button)`
  width: 100%;
  max-width: 200px;
  height: 36px;
  padding: 8px 14px;
  font-size: 14px;
  line-height: 20px;
  background-color: ${(props) => (props.disabled ? gray[100] : primary[50])} !important;
  color: ${(props) => (props.disabled ? gray[500] : primary[700])} !important;

  & svg {
    fill: ${(props) => (props.disabled ? gray[500] : primary[700])};
  }

  &:hover {
    background-color: ${primary[700]} !important;
    color: ${primary[50]} !important;

    & svg {
      fill: ${primary[50]};
    }
  }
`;

export const StyledPopper = styled(Popper)`
  width: 100%;
  max-width: ${(props: { maxWidth?: number }) => `${props.maxWidth}px`};
  background-color: #fff;
  padding: 12px 8px;
  border-radius: 8px;
  box-shadow: ${shadows.lg};
  z-index: 10000;
`;

export const StyledAutocompletePopper = styled('div')`
  position: static !important;
  margin-top: 16px;

  && > .MuiAutocomplete-paper {
    box-shadow: none;
    border-radius: 0;
    background-color: #fff;

    & .MuiAutocomplete-option {
      padding: 10px 16px;

      &[aria-selected='true'] {
        background-color: transparent;
      }

      & > .MuiFormControlLabel-root {
        gap: 12px;

        & .MuiFormControlLabel-label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
`;

export const IconWrapper = styled('div')`
  position: absolute;
  right: 14px;
  top: 12px;
  ${(props: { open: boolean }) =>
    props.open
      ? `
    transform: rotate(180deg);
    top: 6px;
  `
      : ''};
`;
