export enum BudgetRestrictions {
  Unlimited = 'Unlimited',
  Prepay = 'Prepay',
  Enforce = 'Enforce',
}

export enum CurrencyTag {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum PriceModel {
  Dynamic = 'dynamic',
  Fixed = 'fixed',
}

export type Account = {
  id: number;
  name: string;
  currencyTag: CurrencyTag;
  organization: string;
  agency: {
    id: number;
    name: string;
    whitelabel: {
      id: number;
      name: string;
    };
  };
  areaId: 1;
  priceModel: PriceModel;
  adminEmail: string;
  revenues: number;
  topUps: number;
  budgetRestrictions: BudgetRestrictions[];
};

export type PatchAccountRequestBody = Pick<Account, 'name'>;

export type PostAccountRequestBody = Pick<Account, 'name' | 'adminEmail' | 'priceModel' | 'currencyTag'>;
