import moment from 'moment';
import { CalculateAbmReportParams, ReportsAbmReportParams, ReportsGroupBy } from 'services/api/types/amb-audiences';
import { FormData } from './components/Filter/types';

export const prepareRequestData = (
  values: FormData,
  orderBy: 'clicks' | 'impressions' | 'ctr',
): CalculateAbmReportParams => {
  return {
    filter: {
      lineIds: values.lineIds,
      campaignIds: values.campaignIds,
      abmAudienceIds: values.abmAudienceIds,
      dateTimeStart: moment(values.dateTimeStart).startOf('day').utcOffset(0, true).toISOString(),
      dateTimeEnd: moment(values.dateTimeEnd).endOf('day').utcOffset(0, true).toISOString(),
    },
    orderBy,
    limit: 10,
  };
};

export const prepareReportsRequestData = (
  values: FormData,
  format: 'json' | 'csv',
  groupBy?: ReportsGroupBy[],
): ReportsAbmReportParams => {
  return {
    filter: {
      lineIds: values.lineIds,
      campaignIds: values.campaignIds,
      abmAudienceIds: values.abmAudienceIds,
      dateTimeStart: moment(values.dateTimeStart).startOf('day').utcOffset(0, true).toISOString(),
      dateTimeEnd: moment(values.dateTimeEnd).endOf('day').utcOffset(0, true).toISOString(),
    },
    groupBy,
    format,
  };
};

export const getOrderByTabIndex = (index: number): 'clicks' | 'impressions' | 'ctr' => {
  if (index === 0) {
    return 'impressions';
  }
  if (index === 1) {
    return 'ctr';
  }
  if (index === 2) {
    return 'clicks';
  }

  return 'impressions';
};
