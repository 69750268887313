import styled from '@emotion/styled';
import { gray } from 'themes/colors';

export const StyledOptionItem = styled('li')`
  gap: 8px;
  padding: 10px 14px;
  color: ${gray[900]};
  justify-content: space-between;
  position: relative;

  & > svg {
    position: absolute;
    right: 14px;
    top: auto;
  }

  &&.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    background-color: ${gray[50]};
  }
`;
