import React, { memo } from 'react';
// material-ui
import { FormControl, FormHelperText, Stack, Tooltip } from '@mui/material';
// libs
import { getIn } from 'formik';
// icons
import { ReactComponent as CheckboxCheckedIcon } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'assets/icons/checkbox-unchecked.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
// styles
import { StyledCheckbox, StyledFormControlLabel } from './styled';
// types
import { CheckboxProps, FormCheckboxProps } from './types';

export const Checkbox: React.FC<CheckboxProps> = ({ tooltip, label, ...rest }) => (
  <StyledFormControlLabel
    {...rest}
    label={
      <Stack flexDirection='row' alignItems='center' gap={1}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} placement='top' arrow>
            <HelpIcon width={16} height={16} />
          </Tooltip>
        )}
      </Stack>
    }
    control={<StyledCheckbox disableRipple icon={<CheckboxUncheckedIcon />} checkedIcon={<CheckboxCheckedIcon />} />}
  />
);

const FormCheckbox: React.FC<FormCheckboxProps> = ({ field, helperText, form: { errors, touched }, ...rest }) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);

  return (
    <FormControl fullWidth error={isError} size='small'>
      <Checkbox {...field} {...rest} checked={field.value} />

      {helperText && !isError && <FormHelperText>{helperText}</FormHelperText>}
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default memo(FormCheckbox);
