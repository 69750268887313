import React, { memo } from 'react';
// material-ui
import { FormControl, FormLabel, TextField } from '@mui/material';
// libs
import { getIn } from 'formik';
// styles
import { StyledDatePicker } from './styled';
// types
import { InputProps } from './../types';

const DatePicker: React.FC<InputProps> = ({
  field,
  label,
  form: { errors, touched, setFieldValue },
  onChange = (value) => setFieldValue(field.name, value),
  ...props
}) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);

  return (
    <FormControl fullWidth>
      {label && <FormLabel>{label}</FormLabel>}

      <StyledDatePicker
        {...field}
        value={field.value ?? ''}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} variant='outlined' error={isError} helperText={isError && errorMessage} />
        )}
        {...props}
      />
    </FormControl>
  );
};

export default memo(DatePicker);
