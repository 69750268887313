import React, { memo } from 'react';
// material-ui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// types
import { DialogPopUpProps } from './types';

const DialogPopUp: React.FC<DialogPopUpProps> = ({
  isOpen,
  title,
  loading,
  cancelButtonLabel = 'Cancel',
  successButtonLabel = 'Confirm',
  onCancel,
  onConfirm,
  children,
  isConfirmButton = true,
  isHideActions = false,
  maxWidth = 'xs',
  fullWidth = true,
}) => {
  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>{children}</DialogContent>

      {!isHideActions && (
        <DialogActions>
          <Button variant='outlined' disableRipple onClick={onCancel}>
            {cancelButtonLabel}
          </Button>

          {isConfirmButton && (
            <LoadingButton loading={loading} variant='contained' disableRipple onClick={onConfirm}>
              {successButtonLabel}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(DialogPopUp);
