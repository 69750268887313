import { FormControlLabel, styled } from '@mui/material';
import { gray } from 'themes/colors';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  gap: 8px;

  & .MuiRadio-root {
    padding: 0;
  }

  & .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 20px;
    color: ${gray[700]};
    font-weight: 500;
    word-break: break-all;
  }
`;
