import axios, { selfserviceAxiosInstance } from 'services/api/axios';
import {
  Campaign,
  CampaignLine,
  CampaignRequestData,
  CampaignStatistics,
  CloneCampaignRequestData,
} from '../types/campaigns';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Campaign>> => {
    const response = await axios.get(queryString ? `/campaigns?${queryString}` : '/campaigns');

    return response.data;
  },
  get: async (id: Campaign['id']): Promise<Campaign> => {
    const response = await axios.get(`/campaigns/${id}`);

    return response.data;
  },
  getLines: async (id: Campaign['id']): Promise<Pagination<CampaignLine>> => {
    const response = await axios.get(`/lines-orchestrator?$skip=0&$sort[id]=-1&$limit=10000&campaignId=${id}`);

    return response.data;
  },
  getStatistics: async (queryString?: string): Promise<CampaignStatistics[]> => {
    const response = await selfserviceAxiosInstance.get(`/line-group/statistics?${queryString}`);

    return response.data;
  },
  create: async (data: CampaignRequestData): Promise<{ id: Campaign['id'] }> => {
    const response = await axios.post('/campaigns', data);

    return response.data;
  },
  update: async (
    id: Campaign['id'],
    data: Partial<Omit<CampaignRequestData, 'accountId'>>,
  ): Promise<{ id: Campaign['id'] }> => {
    const response = await axios.patch(`/campaigns/${id}`, data);

    return response.data;
  },
  clone: async (id: Campaign['id'], data: CloneCampaignRequestData): Promise<{ id: Campaign['id'] }> => {
    const response = await axios.post(`/campaigns/${id}/clone`, data);

    return response.data;
  },
};
