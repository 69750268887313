import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import CustomSortHeader from './ReportTable/components/CustomSortHeader';
import { SortState } from './ReportTable';

export const tabs = ['Ungrouped', 'By Domain', 'By Domain and Campaign'];

type GetColumnsParams = {
  sortState: SortState | null;
  onSort: (state: SortState | null) => void;
};

const handleSort = (field: string, sortState: SortState | null, callback: (state: SortState | null) => void) => {
  if (!sortState || sortState.field !== field) {
    callback({ sortType: 'asc', field });
    return;
  }

  if (sortState.field === field) {
    if (sortState.sortType === 'asc') {
      callback({ sortType: 'desc', field });
      return;
    }

    if (sortState.sortType === 'desc') {
      callback(null);
      return;
    }
  }
};

export const getUnGroupedColumns = ({ sortState, onSort }: GetColumnsParams): ColumnDef<any, any>[] => [
  {
    accessorKey: 'domainName',
    header: 'Company/Domain',
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'campaignName',
    header: 'Campaign name',
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'lineName',
    header: 'Line name',
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'ctr',
    // header: 'CTR',
    header: () => (
      <CustomSortHeader
        title='CTR'
        sortType={sortState?.field === 'ctr' ? sortState?.sortType : undefined}
        onClick={() => handleSort('ctr', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    cell: ({ getValue }) => (getValue() !== 0 ? `${getValue().toFixed(2)}%` : `${getValue()}%`),
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'impressions',
    // header: 'Impressions',
    header: () => (
      <CustomSortHeader
        title='Impressions'
        sortType={sortState?.field === 'impressions' ? sortState?.sortType : undefined}
        onClick={() => handleSort('impressions', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'clicks',
    // header: 'Clicks',
    header: () => (
      <CustomSortHeader
        title='Clicks'
        sortType={sortState?.field === 'clicks' ? sortState?.sortType : undefined}
        onClick={() => handleSort('clicks', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'dateTime',
    header: 'Date time',
    footer: (props) => props.column.id,
    cell: ({ getValue }) => moment(getValue()).format('D MMM YYYY'),
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export const getDomainColumns = ({ sortState, onSort }: GetColumnsParams): ColumnDef<any, any>[] => [
  {
    accessorKey: 'domainName',
    header: 'Company/Domain',
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'ctr',
    // header: 'CTR',
    header: () => (
      <CustomSortHeader
        title='CTR'
        sortType={sortState?.field === 'ctr' ? sortState?.sortType : undefined}
        onClick={() => handleSort('ctr', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    cell: ({ getValue }) => (getValue() !== 0 ? `${getValue().toFixed(2)}%` : `${getValue()}%`),
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'impressions',
    // header: 'Impressions',
    header: () => (
      <CustomSortHeader
        title='Impressions'
        sortType={sortState?.field === 'impressions' ? sortState?.sortType : undefined}
        onClick={() => handleSort('impressions', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'clicks',
    // header: 'Clicks',
    header: () => (
      <CustomSortHeader
        title='Clicks'
        sortType={sortState?.field === 'clicks' ? sortState?.sortType : undefined}
        onClick={() => handleSort('clicks', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export const getDomainCampaignColumns = ({ sortState, onSort }: GetColumnsParams): ColumnDef<any, any>[] => [
  {
    accessorKey: 'domainName',
    header: 'Company/Domain',
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'campaignName',
    header: 'Campaign name',
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'ctr',
    // header: 'CTR',
    header: () => (
      <CustomSortHeader
        title='CTR'
        sortType={sortState?.field === 'ctr' ? sortState?.sortType : undefined}
        onClick={() => handleSort('ctr', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    cell: ({ getValue }) => (getValue() !== 0 ? `${getValue().toFixed(2)}%` : `${getValue()}%`),
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'impressions',
    // header: 'Impressions',
    header: () => (
      <CustomSortHeader
        title='Impressions'
        sortType={sortState?.field === 'impressions' ? sortState?.sortType : undefined}
        onClick={() => handleSort('impressions', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: 'clicks',
    // header: 'Clicks',
    header: () => (
      <CustomSortHeader
        title='Clicks'
        sortType={sortState?.field === 'clicks' ? sortState?.sortType : undefined}
        onClick={() => handleSort('clicks', sortState, onSort)}
      />
    ),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
];
