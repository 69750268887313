// material-ui
import { Button, styled, Typography } from '@mui/material';
// components
import { Select } from 'components';
// styles
import { gray, primary } from 'themes/colors';

export const PaginationWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const PaginationCounts = styled('div')`
  margin: 0 54px;
  display: flex;
  gap: 2px;
`;

export const PaginationDots = styled('div')`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${gray[500]};
`;

export const PaginationButton = styled(Button)<{ isActive: boolean }>`
  width: 40px;
  height: 40px;

  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${primary[50]};
    color: ${primary[600]};
  `};
`;

export const GoToPageWrapper = styled('div')`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GoToPageText = styled(Typography)`
  font-weight: 500;
  color: ${gray[800]};
`;

export const StyledSelect = styled(Select)`
  font-weight: 600;
  color: ${gray[500]};
`;
