import axios from 'services/api/axios';
import { Country } from '../types/countries';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Country>> => {
    const response = await axios.get(queryString ? `/countries?${queryString}` : '/countries');

    return response.data;
  },
};
