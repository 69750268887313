import { Button, Divider, Popper, styled, Typography } from '@mui/material';
import { gray, primary } from 'themes/colors';
import { shadows } from 'themes/shadows';

export const StyledButton = styled(Button)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  padding: 0;
  gap: 0;

  &:hover {
    color: ${gray[500]};
    background-color: transparent;

    & svg {
      stroke: ${gray[500]};
    }
  }
`;

export const Label = styled(Typography)`
  font-weight: 500;
  line-height: 20px;
  color: ${gray[700]};
  margin-bottom: 6px;
`;

export const ValuesPreview = styled('div')`
  width: 100%;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${gray[300]};
  box-shadow: ${shadows.xs};
  position: relative;
  gap: 8px;
  height: 44px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${gray[900]};

  &:hover {
    border-color: ${primary[300]};
  }

  & > svg {
    position: absolute;
    right: 14px;
    top: auto;
  }
`;

export const Placeholder = styled(Typography)`
  color: ${gray[500]};
  font-weight: 500;
  line-height: 24px;
`;

export const StyledPopper = styled(Popper)`
  width: 100%;
  max-width: 336px;
  background-color: #fff;
  padding: 12px 8px;
  border-radius: 8px;
  box-shadow: ${shadows.lg};
`;

export const StyledAutocompletePopper = styled('div')`
  && > .MuiAutocomplete-paper {
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;

    & .MuiAutocomplete-option {
      padding: 10px 16px;

      &[aria-selected='true'] {
        background-color: transparent;
      }

      & > .MuiFormControlLabel-root {
        gap: 12px;

        & .MuiFormControlLabel-label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
`;

export const ToggleSelectButton = styled(Button)`
  width: 100%;
  height: 36px;
  padding: 8px 14px;
  font-size: 14px;
  line-height: 20px;
  background-color: ${primary[50]};
  color: ${primary[700]};

  &:hover {
    color: ${primary[50]};

    & svg {
      fill: ${primary[50]};
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 20px 0;
  border-color: ${gray[200]};
`;
