/* eslint-disable camelcase */
export const metricsLabels: { [key: string]: string } = {
  bids: 'Bids',
  impressions: 'Impressions',
  clicks: 'Clicks',
  buys: 'Buys',
  registrations: 'Registrations',
  subscriptions: 'Subscriptions',
  funnelStep1: 'Funnel step 1',
  funnelStep2: 'Funnel step 2',
  funnelStep3: 'Funnel step 3',
  installs: 'Installs',
  landings: 'Landings',
  views: 'Views',
  firstQuartileViews: 'First quartile views',
  midPointViews: 'Mid point views',
  thirdQuartileViews: 'Third quartile views',
  completedViews: 'Completed views',
  otherConversions: 'Other conversions',
  mediaSpend: 'Media spend',
  thirdPartySpend: 'Third party spend',
  totalSpend: 'Total spend',
  cpc: 'CPC',
  cpm: 'CPM',
  cp_buys: 'CP buys',
  cp_funnel_step_1: 'CP funnel step 1',
  cp_funnel_step_2: 'CP funnel step 2',
  cp_funnel_step_3: 'CP funnel step 3',
  cp_installs: 'CP installs',
  cp_landings: 'CP landings',
  cp_registrations: 'CP registrations',
  cp_subscriptions: 'CP subscriptions',
  cp_views: 'CP views',
  cp_first_quartile_views: 'CP first quartile views',
  cp_mid_point_views: 'CP mid point views',
  cp_third_quartile_views: 'CP third quartile views',
  cp_completed_views: 'CP completed views',
  cp_skips: 'CP skips',
  cp_other_conversions: 'CP other conversions',
  clear_rate: 'Clear rate',
  ctr: 'CTR',
  buys_rate: 'Buys rate',
  funnel_step_1_rate: 'Funnel step 1 rate',
  funnel_step_2_rate: 'Funnel step 2 rate',
  funnel_step_3_rate: 'Funnel step 3 rate',
  installs_rate: 'Installs rate',
  landings_rate: 'Landings rate',
  registrations_rate: 'Registrations rate',
  subscriptions_rate: 'Subscriptions rate',
  views_rate: 'Views rate',
  first_quartile_views_rate: 'First quartile views rate',
  midPointViewsRate: 'Mid point views rate',
  third_quartile_views_rate: 'Third quartile views rate',
  completed_views_rate: 'Completed views rate',
  skips_rate: 'Skips rate',
  other_conversions_rate: 'Other conversions rate',
};

export const dimensionsLabel: { [key: string]: string } = {
  dateTime: 'Date time',
  accountId: 'Account id',
  accountName: 'Account name',
  adSize: 'Ad size',
  advertiserBundle: 'Advertiser bundle',
  advertiserDomain: 'Advertiser domain',
  agencyId: 'Agency id',
  agencyName: 'Agency name',
  campaignId: 'Campaign id',
  campaignName: 'Campaign name',
  country: 'Country',
  creativeId: 'Creative id',
  creativeName: 'Creative name',
  creativeSize: 'Creative size',
  creativeType: 'Creative type',
  lineCreativeId: 'Line creative id',
  lineId: 'Line id',
  lineName: 'Line name',
};
