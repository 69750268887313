import { formatNumber } from 'utils';
import { FiltersState } from './components/Filters';

export const renderCell = (value: string | null) => {
  if (Number.isNaN(Number(value))) {
    return value;
  }

  if (Number(value) % 1 === 0) {
    return formatNumber(Number(value));
  }

  if (Number(value) % 1 === 0) {
    return formatNumber(Number(value));
  }

  return formatNumber(Number(Number(value).toFixed(2)));
};

export const applyFilter = (data: any[], filters: FiltersState) => {
  if (filters.domainList.length) {
    return data.filter((item) => filters.domainList.includes(item.domainName));
  }

  return data;
};
