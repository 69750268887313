import styled from '@emotion/styled';
import { error } from 'themes/colors';

export const ErrorDot = styled('div')`
  position: absolute;
  right: 2px;
  top: 2px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${error[500]};
`;
