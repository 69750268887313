import React, { memo } from 'react';
// material-ui
import { FormControl, FormHelperText, FormLabel, Stack } from '@mui/material';
// libs
import { getIn } from 'formik';
// icons
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
// styles
import { Placeholder, StyledSelect, StyledSelectItem } from './styled';
// types
import { FormSelectProps, SelectOption, SelectProps } from './types';

export const Select: React.FC<SelectProps> = ({
  options,
  value,
  label,
  required,
  extractValue = (option: SelectOption) => option.value,
  extractLabel = (option: SelectOption) => option.label,
  placeholder,
  isError,
  ...rest
}) => {
  const renderValue = (value: SelectProps['value']) => {
    const selectedOption = options.find((option) => extractValue(option) === value);

    return selectedOption ? extractLabel(selectedOption) : <Placeholder variant='body1'>{placeholder}</Placeholder>;
  };

  return (
    <Stack flex={1}>
      {label && (
        <FormLabel sx={{ mb: '6px' }} required={required}>
          {label}
        </FormLabel>
      )}

      <StyledSelect
        {...rest}
        error={isError}
        displayEmpty
        value={value ?? ''}
        renderValue={renderValue}
        IconComponent={ChevronDownIcon}
      >
        {options?.map((option) => (
          <StyledSelectItem
            key={extractValue(option)}
            value={extractValue(option)}
            disableRipple
            disabled={option.disabled}
          >
            {extractLabel(option)} {value === extractValue(option) ? <CheckIcon /> : null}
          </StyledSelectItem>
        ))}
      </StyledSelect>
    </Stack>
  );
};

const FormSelect: React.FC<FormSelectProps> = ({ helperText, field, form: { errors, touched }, sx, ...rest }) => {
  const errorMessage = getIn(errors, field.name);
  const isError = errorMessage && getIn(touched, field.name);

  return (
    <FormControl fullWidth size='small' sx={sx}>
      <Select {...field} {...rest} isError={isError} />

      {helperText && !isError && <FormHelperText>{helperText}</FormHelperText>}
      {isError && <FormHelperText error={isError}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default memo(FormSelect);
