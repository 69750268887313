import Cookies from 'js-cookie';
import { AUTH_TOKEN_KEY, STUDIO_APP_TOUR_KEY } from 'services/constants';

const getAuthToken = (): string | undefined => {
  try {
    const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);

    if (authToken) return JSON.parse(authToken);
  } catch (error: any) {
    console.log('LocalStorage auth get error:', error.message);
  }
};

const updateAuthToken = (token: string) => {
  try {
    window.localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(token));

    Cookies.set(AUTH_TOKEN_KEY, JSON.stringify(token), { domain: process.env.REACT_APP_DOMAIN, expires: 30 });
    Cookies.set(STUDIO_APP_TOUR_KEY, JSON.stringify(true), { domain: process.env.REACT_APP_DOMAIN, expires: 30 });

    if (process.env.REACT_APP_ENV === 'dev') {
      Cookies.set(AUTH_TOKEN_KEY, JSON.stringify(token), { domain: 'localhost', expires: 30 });
      Cookies.set(STUDIO_APP_TOUR_KEY, JSON.stringify(true), { domain: 'localhost', expires: 30 });
    }
  } catch (error: any) {
    console.log('LocalStorage auth set error:', error.message);
  }
};

const clearAuthToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY);

  Cookies.remove(AUTH_TOKEN_KEY, { domain: process.env.REACT_APP_DOMAIN, expires: 30 });
  Cookies.remove(AUTH_TOKEN_KEY, { domain: 'localhost', expires: 30 });

  Cookies.remove(STUDIO_APP_TOUR_KEY, { domain: process.env.REACT_APP_DOMAIN, expires: 30 });
  Cookies.remove(STUDIO_APP_TOUR_KEY, { domain: 'localhost', expires: 30 });
};

export { clearAuthToken, getAuthToken, updateAuthToken };
