import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useStore } from 'context/StoreContext';
import api from 'services/api';
import { PageHeader } from 'components';
import AccountInformationForm from '../components/AccountInformationForm';

const MyAccount = () => {
  const { user } = useStore();

  const { data, isLoading } = useQuery(['account', user.entityId], () => api.accounts.getOne(user.entityId), {
    enabled: Boolean(user.entityId),
  });

  if (isLoading) {
    return <CircularProgress size={28} sx={{ alignSelf: 'center' }} />;
  }

  return (
    <div>
      <PageHeader title='Edit account' id={`${data?.id}`} name={data?.name} />

      {data && <AccountInformationForm data={data} />}
    </div>
  );
};

export default MyAccount;
