import axios from 'services/api/axios';
import { CreateSegmentRequestBody, Segment, UpdateSegmentRequestBody } from '../types/my-audiences';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Segment>> => {
    const response = await axios.get(queryString ? `/audiences/myaudiences?${queryString}` : '/audiences/myaudiences');

    return response.data;
  },
  getOne: async (id: string | number): Promise<Segment> => {
    const response = await axios.get(`/audiences/myaudiences/${id}`);

    return response.data;
  },
  create: async (data: CreateSegmentRequestBody): Promise<number> => {
    const response = await axios.post('/audiences/myaudiences', data);

    return response.data;
  },
  update: async (id: string | number, data: UpdateSegmentRequestBody): Promise<number> => {
    const response = await axios.patch(`/audiences/myaudiences/${id}`, data);

    return response.data;
  },
  delete: async (id: string | number): Promise<any> => {
    const response = await axios.delete(`/audiences/myaudiences/${id}`);

    return response.data;
  },
  uploadFile: async (id: string | number, formData: FormData): Promise<any> => {
    const response = await axios.post(`/audiences/${id}/myaudiences-upload`, formData);

    return response.data;
  },
  deleteFile: async (id: string | number): Promise<any> => {
    const response = await axios.delete(`/audiences/myaudiences/file-delete/${id}`);

    return response.data;
  },
};
