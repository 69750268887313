import { certisDspAxiosInstance } from '../axios';
import {
  CreateNotificationSettingsProfileRequestBody,
  NotificationSettingProfile,
  UpdateNotificationSettingsProfileRequestBody,
} from '../types/notification-profiles';

export default {
  getList: async (): Promise<NotificationSettingProfile[]> => {
    const response = await certisDspAxiosInstance.get('/notification-setting-profiles');

    return response.data;
  },
  create: async (data: CreateNotificationSettingsProfileRequestBody): Promise<NotificationSettingProfile> => {
    const response = await certisDspAxiosInstance.post('/notification-setting-profiles', data);

    return response.data;
  },
  update: async (
    id: number,
    data: UpdateNotificationSettingsProfileRequestBody,
  ): Promise<NotificationSettingProfile> => {
    const response = await certisDspAxiosInstance.put(`/notification-setting-profiles/${id}`, data);

    return response.data;
  },
  delete: async (id: number): Promise<NotificationSettingProfile> => {
    const response = await certisDspAxiosInstance.delete(`/notification-setting-profiles/${id}`);

    return response.data;
  },
};
