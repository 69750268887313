// icons
import { FC } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as SortArrowDownIcon } from 'assets/icons/sort-arrow-down.svg';
import { ReactComponent as SortArrowUpIcon } from 'assets/icons/sort-arrow-up.svg';

type CustomSortHeaderProps = {
  title: string;
  sortType: 'asc' | 'desc' | undefined;
  onClick: () => void;
};

const CustomSortHeader: FC<CustomSortHeaderProps> = ({ title, sortType, onClick }) => {
  return (
    <Box onClick={onClick} display='flex' alignItems='center' sx={{ cursor: 'pointer' }}>
      {title}{' '}
      {sortType ? (
        {
          asc: <SortArrowUpIcon />,
          desc: <SortArrowDownIcon />,
        }[sortType]
      ) : (
        <SortIcon />
      )}
    </Box>
  );
};

export default CustomSortHeader;
