import axios from 'services/api/axios';
import { Deal, DealRequestData } from '../types/deals';
import { ListItem } from '../types/lists';
import { Pagination } from '../types/pagination';

export default {
  get: async (id: number, queryString?: string): Promise<Pagination<Deal>> => {
    const response = await axios.get(queryString ? `/lists/${id}/deals?${queryString}` : `/lists/${id}/deals`);

    return response.data;
  },
  create: async (id: ListItem['id'], data: DealRequestData): Promise<ListItem['id']> => {
    const response = await axios.post(`/lists/${id}/deals`, data);

    return response.data;
  },
  delete: async (id: number, dealID: Deal['id']): Promise<void> => {
    const response = await axios.delete(`/lists/${id}/deals/${dealID}`);

    return response.data;
  },
};
