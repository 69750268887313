import React, { memo, useMemo } from 'react';
// material-ui
import { Tab, Tabs as MaterialUITabs, TabsProps as MUITabsProps, useMediaQuery } from '@mui/material';
// icons
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
// styles
import { ErrorDot } from './styled';
// types
import { TabsProps } from './types';

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  handleChange,
  sx,
  errors,
  mediaQuery = '(min-width: 475px)',
}) => {
  const matches = useMediaQuery(mediaQuery);

  const tabsProps = useMemo(
    (): Pick<MUITabsProps, 'variant' | 'scrollButtons' | 'allowScrollButtonsMobile'> =>
      matches || tabs.length <= 2
        ? {
            variant: 'fullWidth',
          }
        : {
            variant: 'scrollable',
            scrollButtons: true,
            allowScrollButtonsMobile: true,
          },
    [tabs, matches],
  );

  const a11yProps = (index: number) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  });

  return (
    <MaterialUITabs
      {...tabsProps}
      value={activeTab}
      onChange={handleChange}
      sx={{ ...sx, borderRadius: 2 }}
      TabScrollButtonProps={{
        disableRipple: true,
      }}
      slots={{
        StartScrollButtonIcon: ChevronLeftIcon,
        EndScrollButtonIcon: ChevronRightIcon,
      }}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={
            <>
              {tab}
              {errors && errors.indexOf(index) > -1 ? <ErrorDot /> : null}
            </>
          }
          disableRipple
          {...a11yProps(index)}
          sx={{
            fontSize: '16px',
            textTransform: 'none',
            textAlign: 'left',
            whiteSpace: 'nowrap',
          }}
        />
      ))}
    </MaterialUITabs>
  );
};

export default memo(Tabs);
