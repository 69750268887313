import React, { Suspense } from 'react';
import { FullScreenLoader } from 'components';

// eslint-disable-next-line react/display-name
const Loadable = (Component: React.ComponentType<any>) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<FullScreenLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
