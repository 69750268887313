import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, CircularProgress, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import routes from 'routes';
import api from 'services/api';
import { Account, CurrencyTag, PatchAccountRequestBody } from 'services/api/types/accounts';
import * as yup from 'yup';
import { FormSelect, Input, SectionTitle } from 'components';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg';

type FormValues = {
  name: string;
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

interface AccountInformationProps {
  data: Account;
}

const AccountInformationForm = ({ data }: AccountInformationProps) => {
  const { t } = useTranslation(['account', 'common']);
  const navigate = useNavigate();

  const params = useParams();

  const { mutate, error, isError, isLoading } = useMutation({
    mutationFn: (data: PatchAccountRequestBody) => api.accounts.updateOne(params?.id as string, data),
    onSuccess: () => navigate(routes.admin.accounts),
  });

  const handleSubmit = (values: FormValues) => {
    if (data && values) {
      mutate({ name: values.name });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Formik
      initialValues={{
        name: data?.name ?? '',
        email: data?.adminEmail ?? '',
        currency: data?.currencyTag ?? '',
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <SectionTitle title={t('Account information')} />

        <Stack flexDirection='row' gap={4} mb={4}>
          <Field name='name' label={t('Name')} placeholder='' component={Input} required />

          <Field name='email' label={t('Account admin email')} placeholder='' component={Input} disabled />
        </Stack>
        <SectionTitle title={t('Financial information')} />

        <Stack flexDirection='row' gap={4} mb={4}>
          <Box flex={0.5} mr={4}>
            <Field
              name='currency'
              label={t('Currency')}
              placeholder=''
              options={[{ label: CurrencyTag.USD, value: CurrencyTag.USD }]}
              component={FormSelect}
              disabled
            />
          </Box>
        </Stack>

        {isError && (
          <Alert severity='error'>
            <>
              <AlertTitle>{t('Error', { ns: 'common' })}</AlertTitle>
              {(error as AxiosError<Error>)?.response?.data?.message}
            </>
          </Alert>
        )}

        <Stack flexDirection='row' alignItems='center' justifyContent='space-between' mt={2}>
          <Button variant='outlined' startIcon={<ArrowBackIcon />} onClick={handleCancel} sx={{ width: 'auto' }}>
            {t('Back', { ns: 'common' })}
          </Button>

          <LoadingButton loading={isLoading} variant='contained' type='submit' sx={{ width: 'auto' }}>
            {t('Save', { ns: 'common' })}
          </LoadingButton>
        </Stack>
      </Form>
    </Formik>
  );
};

export default AccountInformationForm;
