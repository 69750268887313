import { Navigate, Outlet } from 'react-router-dom';
import { useStore } from 'context/StoreContext';
import { AccessRuleAction } from 'services/api/types/access-control';
import { checkRouteAccess } from 'utils';

interface ProtectedRouteProps {
  accessRuleAction: AccessRuleAction;
}

function ProtectedRoute({ accessRuleAction }: ProtectedRouteProps) {
  const { accessControl } = useStore();

  return checkRouteAccess(accessControl, accessRuleAction) ? <Outlet /> : <Navigate to='/' />;
}

export default ProtectedRoute;
