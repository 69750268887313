// material-ui
import { Button, styled } from '@mui/material';
// styles
import { primary } from 'themes/colors';
import { shadows } from 'themes/shadows';

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  padding: 10px 9px;
  box-shadow: none;
  background-color: transparent;
  filter: drop-shadow(${shadows.xs});

  & > svg {
    width: 24px;
    height: 24px;
    fill: ${primary[500]};
  }

  &:hover {
    color: #fff;
    background-color: ${primary[600]};
    border-color: ${primary[600]};

    & > svg {
      fill: #fff;
    }
  }

  ${({ isActive }) =>
    isActive &&
    `
      background-color: ${primary[700]};
      border-color: ${primary[700]};

      & > svg {
        fill: #fff;
      }
  `}
`;
