import axios from 'services/api/axios';
import { Exchange } from '../types/exchanges';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Exchange>> => {
    const response = await axios.get(queryString ? `/exchanges?${queryString}` : '/exchanges');

    return response.data;
  },
};
