import { FC } from 'react';
import { Box } from '@mui/material';
import groupBy from 'lodash.groupby';
import { TabPanel, Tabs } from 'components';
import Chart from './components/Chart';

type ChartsProps = {
  currentTab: number;
  data: any[];
  onTabChange: (_: React.SyntheticEvent, value: number) => void;
  isLoading: boolean;
};

const Charts: FC<ChartsProps> = ({ data, currentTab, isLoading, onTabChange }) => {
  const tabs = ['Impressions by Domain', 'CTR by Domain', 'Clicks by Domain'];

  const groupedDataByDomain = Object.entries(groupBy(data, 'domainName')).map(([key, value]: [string, any[]]) => {
    return [
      key,
      [
        ...value.map((item) => {
          return {
            date: Number(new Date(item.dateTime)),
            ...item,
          };
        }),
      ].sort((a, b) => a.date - b.date),
    ];
  });

  return (
    <Box my={4}>
      <Tabs tabs={tabs} activeTab={currentTab} handleChange={onTabChange} />

      <TabPanel value={currentTab} index={0}>
        <Chart isLoading={isLoading} data={groupedDataByDomain} metric='impressions' />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Chart isLoading={isLoading} data={groupedDataByDomain} metric='ctr' />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <Chart isLoading={isLoading} data={groupedDataByDomain} metric='clicks' />
      </TabPanel>
    </Box>
  );
};

export default Charts;
