import axios from 'services/api/axios';
import { CloneFolderRequestData, Folder, FolderRequestData } from '../types/folders';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Folder>> => {
    const response = await axios.get(queryString ? `/folders?${queryString}` : '/folders');

    return response.data;
  },
  get: async (id: Folder['id']): Promise<Folder> => {
    const response = await axios.get(`/folders/${id}`);

    return response.data;
  },
  create: async (data: FolderRequestData): Promise<Folder['id']> => {
    const response = await axios.post('/folders', data);

    return response.data;
  },
  update: async (id: Folder['id'], data: Omit<FolderRequestData, 'accountId'>): Promise<Folder['id']> => {
    const response = await axios.patch(`/folders/${id}`, data);

    return response.data;
  },
  clone: async (id: Folder['id'], data: CloneFolderRequestData): Promise<{ id: Folder['id'] }> => {
    const response = await axios.post(`/folders/${id}/clone`, data);

    return response.data;
  },
  delete: async (id: Folder['id']): Promise<void> => {
    const response = await axios.delete(`/folders/${id}`);

    return response.data;
  },
};
