import { lazy } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useStore } from 'context/StoreContext';
import api from 'services/api';
import { clearAuthToken } from 'services/storage/auth';
import Loadable from 'components/Loadable';

const SignInPage = Loadable(lazy(() => import('pages/SignIn')));

const PrivateRoute = () => {
  const navigate = useNavigate();
  const { token, setUser, setToken } = useStore();

  // TODO: need refactoring for accepting authorized users
  const { data, isLoading, isFetching } = useQuery(['profile', token], () => api.auth.getProfile(), {
    enabled: !!token,
    onSuccess: (data) => {
      setUser(data);
    },
    onError: (err: AxiosError) => {
      if (err?.response?.status === 401) {
        clearAuthToken();
        setToken('');
        navigate('/');
      }
    },
  });

  return token ? <Outlet /> : <SignInPage />;
};

export default PrivateRoute;
