import { certisDspAxiosInstance } from 'services/api/axios';
import { CalculateReportParams, CreateReportRequestBody, Report } from '../types/reports';

export default {
  create: async (data: CreateReportRequestBody): Promise<Report> => {
    const response = await certisDspAxiosInstance.post('/reports', data);

    return response.data;
  },
  getList: async (params?: any): Promise<Report[]> => {
    const response = await certisDspAxiosInstance.get('/reports', { params });

    return response.data;
  },
  getOne: async (id: number): Promise<Report> => {
    const response = await certisDspAxiosInstance.get(`/reports/${id}`);

    return response.data;
  },
  getFile: async (id: number, format: 'json' | 'csv' = 'json'): Promise<any> => {
    const response = await certisDspAxiosInstance.get(`/reports/${id}/download-file?format=${format}`);

    return response.data;
  },
  delete: async (id: number): Promise<any> => {
    const response = await certisDspAxiosInstance.delete(`/reports/${id}`);

    return response.data;
  },
  calculate: async (data: CalculateReportParams, format: 'csv' | 'json'): Promise<any> => {
    const response = await certisDspAxiosInstance.post('/reports/calculate', { type: format, ...data });

    return response.data;
  },
};
