import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// material-ui
import { Chip, Tooltip } from '@mui/material';
// types
import { CopyToClipboardButtonProps } from './types';

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ title }) => {
  const { t } = useTranslation('common');
  const [isCopied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard
      .writeText(title)
      .then(() => {
        setCopied(true);

        setTimeout(function () {
          setCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Tooltip title={isCopied ? t('Copied') : ''} placement='bottom' arrow>
      <Chip label={title} onClick={handleClick} />
    </Tooltip>
  );
};

export default CopyToClipboardButton;
