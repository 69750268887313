// material-ui
import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(249, 250, 251, 0.7);
  z-index: 1;
`;
