export enum AccessRuleAction {
  AnalyticsAvailability = 'analytics.availability',
  AnalyticsMultidimensionalReport = 'analytics.multidimensionalReport',
  AnalyticsStandardReport = 'analytics.standardReport',
  AnalyticsDeepDataReport = 'analytics.deepDataReport',
  AnalyticsScheduledReport = 'analytics.scheduledReport',
}

export const analyticsAccessRulesPlaceholder = [
  {
    action: AccessRuleAction.AnalyticsStandardReport,
    isAllowed: true,
  },
  {
    action: AccessRuleAction.AnalyticsScheduledReport,
    isAllowed: true,
  },
  {
    action: AccessRuleAction.AnalyticsAvailability,
    isAllowed: true,
  },
  {
    action: AccessRuleAction.AnalyticsMultidimensionalReport,
    isAllowed: true,
  },
  {
    action: AccessRuleAction.AnalyticsDeepDataReport,
    isAllowed: true,
  },
];

export interface AccessRule {
  action: AccessRuleAction;
  isAllowed: boolean;
  id: 0;
  accessControlListId: number;
  createdAt: string;
  updatedAt: string;
}

export interface AccessControlAccount {
  id: number;
  agencyId: number;
  campaignsCount: number;
  currencyTag: string;
  externalId: number;
  name: string;
  revenues: number;
  topUps: number;
  budgetRestrictions: string[];
  organization: string;
  platformDomain: string;
  areaId: string;
  priceModel: string;
  adminEmail: string;
}

export interface AccessControl {
  id: number;
  accessRules: AccessRule[];
  account: AccessControlAccount;
  createdAt: 0;
  updatedAt: 0;
}

export interface UpdateAccessControlExternalRequestBody {
  accessControlLists: {
    externalAccountId: number;
    accessRules: Pick<AccessRule, 'action' | 'isAllowed'>[];
  }[];
}
