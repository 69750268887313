import React, { memo, useCallback, useMemo } from 'react';
// material-ui
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps as MUIAutocompleteProps,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  TextField,
  Tooltip,
} from '@mui/material';
// libs
import { useInfiniteQuery } from '@tanstack/react-query';
import { getIn } from 'formik';
// utils
import { getOptionsConfig } from './utils';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
// icons
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/cross.svg';
// types
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
// styles
import { StyledOptionItem } from './styled';
import { AutocompleteProps, Option, OptionValue } from './types';

const Autocomplete = <T extends Option>(
  props: AutocompleteProps<T> &
    Omit<MUIAutocompleteProps<T, boolean, boolean, boolean>, 'options' | 'value' | 'title' | 'renderInput'>,
) => {
  const {
    inputMaxLength,
    limitTags,
    label,
    field,
    required,
    placeholder,
    optionsKey,
    disabled,
    multiple = false,
    disableCheckIcon,
    helperText,
    optionsFetchParams,
    loading,
    options: customOptions,
    excludeOptions,
    form: { errors, touched, setFieldValue },
    extractValue = (option: T) => option?.value ?? null,
    extractLabel = (option: T) => option?.label ?? null,
    onChange,
    onChangeHelper,
    labelHint,
    ...rest
  } = props;
  const errorMessage = getIn(errors, field.name);
  const isError = !!(errorMessage && getIn(touched, field.name));

  const optionsConfig = optionsKey && getOptionsConfig(optionsKey);

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`${optionsKey}Options`, optionsFetchParams],
    async ({ pageParam = 0 }) => optionsConfig?.api(pageParam, optionsFetchParams),
    {
      enabled: !disabled && !!optionsConfig,
      getNextPageParam: (lastPage) => {
        if (lastPage) {
          const currentPage = lastPage.skip / lastPage.limit + 1;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const pageCount = Math.ceil((lastPage.total || lastPage.count) / lastPage.limit);
          const skip = currentPage * lastPage.limit;

          return currentPage < pageCount ? skip : undefined;
        }
      },
    },
  );

  const storedOptions = useMemo(
    () =>
      data?.pages.reduce(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (res, page) => [...res, ...((page?.data || page?.rows) as Record<string, string | number | boolean>[])],
        [] as Record<string, string | number | boolean>[],
      ),
    [data],
  );

  const options = useMemo(
    () => (storedOptions && optionsConfig ? optionsConfig.serializeOptions(storedOptions) : []),
    [storedOptions, optionsConfig],
  );

  const currentOptions = (customOptions || options).filter(
    (item) => !excludeOptions?.find((excludeItemValue) => item.value === excludeItemValue),
  );

  const currentValue = useMemo(() => {
    const value = field.value === undefined ? (multiple ? [] : null) : field.value;

    return multiple
      ? currentOptions.filter((option: T) => (value as OptionValue[]).includes(extractValue(option)))
      : currentOptions.find((option: T) => extractValue(option) === (value as OptionValue)) || null;
  }, [field.value, multiple, currentOptions, extractValue]);

  const handleChange = useCallback<NonNullable<MUIAutocompleteProps<T, boolean, boolean, boolean>['onChange']>>(
    (e, option) => {
      const newValue = multiple ? (option as T[]).map((opt: T) => extractValue(opt)) : extractValue(option as T);

      onChangeHelper?.(newValue, field.value);

      setFieldValue(field.name, newValue);
    },
    [multiple, extractValue, onChangeHelper, field.value, field.name, setFieldValue],
  );

  return (
    <MUIAutocomplete
      limitTags={limitTags}
      {...field}
      fullWidth
      disableClearable
      multiple={multiple}
      disabled={disabled}
      loading={loading || isLoading}
      value={currentValue}
      options={currentOptions}
      onChange={onChange ?? handleChange}
      getOptionLabel={(option: any) => extractLabel(option)}
      disableCloseOnSelect={multiple}
      popupIcon={<ChevronDownIcon />}
      ChipProps={{ deleteIcon: <DeleteIcon /> }}
      renderInput={(params) => {
        return (
          <FormControl fullWidth>
            {!!label && (
              <Box display='flex' alignItems='center' gap={0.5}>
                <FormLabel required={required}>{label}</FormLabel>
                {labelHint ? (
                  <span>
                    <Tooltip title={labelHint}>
                      <HelpIcon width={18} height={18} />
                    </Tooltip>
                  </span>
                ) : null}
              </Box>
            )}

            <TextField
              {...params}
              error={isError}
              placeholder={multiple && (currentValue as T[])?.length ? undefined : placeholder}
              helperText={isError ? errorMessage : helperText}
              inputProps={{ ...params.inputProps, maxLength: inputMaxLength }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {!disabled && optionsConfig && (isLoading || isFetching) ? <CircularProgress size={16} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </FormControl>
        );
      }}
      renderOption={(props, option, { selected }) =>
        multiple && !disableCheckIcon ? (
          <StyledOptionItem {...props}>
            {extractLabel(option)} {selected ? <CheckIcon /> : null}
          </StyledOptionItem>
        ) : (
          <li {...props}>{extractLabel(option)}</li>
        )
      }
      ListboxProps={{
        role: 'list-box',
        onScroll: ({ currentTarget }) => {
          const scrollPosition = currentTarget.scrollTop + currentTarget.clientHeight;

          if (currentTarget.scrollHeight - scrollPosition <= 1 && hasNextPage) {
            fetchNextPage();
          }
        },
      }}
      {...rest}
    />
  );
};

export default memo(Autocomplete);
