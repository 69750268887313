export enum AuthUserType {
  Account = 'account',
  Agency = 'agency',
}

export type AuthUser = {
  id: number;
  name: string;
  email: string;
  type: AuthUserType;
  permissions: string[];
  mfaEnabled: boolean;
  entityId: number;
  headless: boolean;
  biDashboardPermission: boolean;
};
