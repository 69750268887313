import React from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { MaterialUIAlert } from './styled';
import { AlertProps } from './types';

const InfoAlert: React.FC<AlertProps> = ({ text, onClose, ...rest }) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <MaterialUIAlert variant='outlined' severity='info' icon={<InfoCircleIcon />} onClose={handleClose} {...rest}>
      <Typography variant='body1'>{text}</Typography>
    </MaterialUIAlert>
  );
};

export default InfoAlert;
