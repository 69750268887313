// material-ui
import { FormControlLabel, styled, Switch } from '@mui/material';
// styles
import { gray } from 'themes/colors';
import { shadows } from 'themes/shadows';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  gap: 12px;

  & .MuiFormControlLabel-label {
    font-weight: 500;
    color: ${gray[700]};
  }
`;

export const StyledSwitch = styled(Switch)`
  padding: 0;
  width: 44px;
  height: 24px;

  & .MuiSwitch-switchBase {
    padding: 2px;

    & .MuiSwitch-thumb {
      box-shadow: ${shadows.sm};
    }

    &.Mui-checked {
      color: #fff;

      & + .MuiSwitch-track {
        opacity: 1;
      }
    }
  }

  & .MuiSwitch-track {
    background-color: ${gray[100]};
    border-radius: 12px;
    opacity: 1;
  }
`;
