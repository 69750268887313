// material-ui
import { Checkbox, FormControlLabel, styled } from '@mui/material';
// styles
import { gray } from 'themes/colors';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  gap: 8px;

  & > .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${gray[700]};
    user-select: none;

    & svg {
      min-width: 16px;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;
