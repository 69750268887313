import React from 'react';
// material-ui
import { ListItemIcon, ListItemText, Menu } from '@mui/material';
// icons
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
// styles
import { MoreButton, StyledLink, StyleMenuItem, Wrapper } from './styled';
// types
import { TableActionMenuProps } from './types';

const TableActionMenu: React.FC<TableActionMenuProps> = ({ actions }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper isMenuOpen={isMenuOpen}>
      <MoreButton disableRipple onClick={handleClick}>
        <MoreIcon />
      </MoreButton>

      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose} MenuListProps={{ sx: { width: 240 } }}>
        {actions.map(({ label, icon, to, onClick, isOpenInNewTab, disabled }, index) => {
          const handleActionClick = () => {
            onClick?.();
            handleClose();
          };

          return (
            <StyleMenuItem key={index} disableRipple onClick={handleActionClick} disabled={disabled}>
              {to ? (
                <StyledLink to={to} target={isOpenInNewTab ? '_blank' : ''}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </StyledLink>
              ) : (
                <>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </>
              )}
            </StyleMenuItem>
          );
        })}
      </Menu>
    </Wrapper>
  );
};

export default TableActionMenu;
