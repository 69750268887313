import api from 'services/api';
import { ABMAudience } from 'services/api/types/amb-audiences';
import { Campaign } from 'services/api/types/campaigns';
import { Country } from 'services/api/types/countries';
import { Folder } from 'services/api/types/folders';
import { Line } from 'services/api/types/lines';
import { Pagination } from 'services/api/types/pagination';
import { TrackingGroup } from 'services/api/types/tracking-groups';
import { OPTIONS_KEYS } from 'services/constants';

export const getOptionsConfig = (optionKey?: OPTIONS_KEYS) => {
  switch (optionKey) {
    case OPTIONS_KEYS.EXCHANGES:
      return {
        api: (queryData?: any) => api.exchanges.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, displayName }) => ({
            value: id,
            label: displayName,
          })),
      };
    case OPTIONS_KEYS.BUNDLE_DOMAINS:
      return {
        api: (queryData?: any) => api.lists.getLists(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.DEALS:
      return {
        api: (queryData?: any) => api.lists.getLists(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.INVENTORIES:
      return {
        api: (queryData?: any) => api.lists.getLists(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.IP_RANGES:
      return {
        api: (queryData?: any) => api.lists.getLists(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.CARRIERS:
      return {
        api: (queryData?: any) => api.carriers.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, operator }) => ({
            value: id,
            label: operator,
          })),
      };
    case OPTIONS_KEYS.COUNTRIES:
      return {
        api: (queryData?: string) => api.countries.getList(queryData),
        serializeOptions: (data: Pagination<Country>) =>
          data.data.map(({ isoCode2, name }) => ({
            value: isoCode2,
            label: name,
          })),
      };
    case OPTIONS_KEYS.CITIES:
      return {
        api: (queryData?: any) => api.cities.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.GEO:
      return {
        api: (queryData?: any) => api.lists.getLists(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.REGION:
      return {
        api: (queryData?: any) => api.lists.getLists(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.ZIPCODE:
      return {
        api: (queryData?: any) => api.lists.getLists(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.DEVICE_MODELS:
      return {
        api: (queryData?: any) => api.deviceModels.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ deviceBrand }) => ({
            value: deviceBrand,
            label: deviceBrand,
          })),
      };
    case OPTIONS_KEYS.ANDROID_VERSIONS:
      return {
        api: (queryData?: any) => api.osVersions.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
      };
    case OPTIONS_KEYS.IOS_VERSIONS:
      return {
        api: (queryData?: any) => api.osVersions.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
      };
    case OPTIONS_KEYS.WINDOWS_VERSIONS:
      return {
        api: (queryData?: any) => api.osVersions.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
      };
    case OPTIONS_KEYS.ALL_AUDIENCES:
      return {
        api: (queryData?: any) => api.allAudiences.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name, audienceType }) => ({
            value: id,
            label: name,
            type: audienceType,
          })),
      };
    case OPTIONS_KEYS.CAMPAIGNS:
      return {
        api: (queryData?: string) => api.campaigns.getList(queryData),
        serializeOptions: (data: Pagination<Campaign>) =>
          data.data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.LINES:
      return {
        api: (queryData?: string) => api.lines.getList(queryData),
        serializeOptions: (data: Pagination<Line>) =>
          data.data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.TRACKING_GROUPS:
      return {
        api: (queryData?: string) => api.trackingGroups.getList(queryData),
        serializeOptions: (data: Pagination<TrackingGroup>) =>
          data.data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.FOLDERS:
      return {
        api: (queryData?: string) => api.folders.getList(queryData),
        serializeOptions: (data: Pagination<Folder>) =>
          data.data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    case OPTIONS_KEYS.DEVICE_LANGUAGES:
      return {
        api: (queryData?: any) => api.deviceLanguages.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ code, name }) => ({
            value: code,
            label: name,
          })),
      };
    case OPTIONS_KEYS.LINE_CREATIVES:
      return {
        api: (id: number) => api.lines.getCreatives(id),
        serializeOptions: (data: Pagination<Line>) =>
          data.data.map(({ id, creativeName }) => ({
            value: id,
            label: creativeName,
          })),
      };
    case OPTIONS_KEYS.SEGMENTS:
      return {
        api: (queryData?: any) => api.segments.getList(queryData),
        serializeOptions: (data: any[]) =>
          data.map(({ id, name, cpmDollars }) => ({
            value: id,
            label: name,
            price: cpmDollars,
          })),
      };
    case OPTIONS_KEYS.ABM_AUDIENCES:
      return {
        api: (queryData?: string) => api.abmAudiences.getList(queryData),
        serializeOptions: (data: ABMAudience[]) =>
          data.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
      };
    default: {
      return null;
    }
  }
};
export { OPTIONS_KEYS };
