// material-ui
import { CircularProgress } from '@mui/material';
// styles
import { Wrapper } from './styled';

const FullScreenLoader: React.FC = () => (
  <Wrapper>
    <CircularProgress size={35} />
  </Wrapper>
);

export default FullScreenLoader;
