import axios from 'services/api/axios';
import { AuthUser } from '../types/auth';

export default {
  getProfile: async (): Promise<AuthUser> => {
    const response = await axios.get('/me');

    return response.data;
  },
};
