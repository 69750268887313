import axios from 'services/api/axios';
import { Pagination } from '../types/pagination';
import { Segment } from '../types/segments';

export default {
  getList: async (queryString?: string): Promise<Pagination<Segment>> => {
    const response = await axios.get(`/segments/dmps?${queryString}`);

    return response.data;
  },
};
