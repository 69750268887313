import React, { memo } from 'react';
// material-ui
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { useFormikContext } from 'formik';
// icons
import { ReactComponent as RadioCheckedIcon } from 'assets/icons/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/icons/radio-unchecked.svg';
// styles
import { StyledFormControlLabel } from './styled';
// types
import { FormRadioProps, RadioOption } from './types';

const RadioGroup: React.FC<FormRadioProps> = ({
  extractValue = (option: RadioOption) => option.value,
  extractLabel = (option: RadioOption) => option.label,
  field,
  options,
  label,
  disabled,
  required,
  ...rest
}) => {
  const matches = useMediaQuery('(min-width: 375px)');

  const { errors } = useFormikContext();

  return (
    <FormControl component='fieldset' fullWidth>
      <MUIRadioGroup {...field} {...rest}>
        {label && (
          <FormLabel required={required} component='legend'>
            {label}
          </FormLabel>
        )}

        <Stack direction='row' gap={matches ? 4 : 2}>
          {options.map((option: RadioOption) => (
            <StyledFormControlLabel
              key={extractValue(option)}
              value={extractValue(option)}
              control={
                <Radio
                  disableRipple
                  size='small'
                  color='primary'
                  icon={<RadioUncheckedIcon />}
                  checkedIcon={<RadioCheckedIcon />}
                  checked={field.value == option.value}
                />
              }
              label={extractLabel(option)}
              disabled={disabled}
            />
          ))}
        </Stack>

        <FormHelperText error>{(errors as any)?.[field?.name] as string}</FormHelperText>
      </MUIRadioGroup>
    </FormControl>
  );
};

export default memo(RadioGroup);
