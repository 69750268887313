import { certisDspAxiosInstance } from '../axios';
import { ConfigName, CreateUpdateInterfaceConfigRequestBody, InterfaceConfigItem } from '../types/interface-configs';

export default {
  getAll: async (): Promise<InterfaceConfigItem[]> => {
    const response = await certisDspAxiosInstance.get('/interface-configs');

    return response.data;
  },
  getOne: async (configName: ConfigName): Promise<InterfaceConfigItem> => {
    const response = await certisDspAxiosInstance.get(`/interface-configs/${configName}`);

    return response.data;
  },
  createUpdate: async (
    configName: ConfigName,
    data: CreateUpdateInterfaceConfigRequestBody,
  ): Promise<InterfaceConfigItem> => {
    const response = await certisDspAxiosInstance.put(`/interface-configs/${configName}`, data);

    return response.data;
  },
  delete: async (configName: ConfigName): Promise<InterfaceConfigItem> => {
    const response = await certisDspAxiosInstance.delete(`/interface-configs/${configName}`);

    return response.data;
  },
};
