import { certisDspAxiosInstance as axios } from 'services/api/axios';
import { AddDomainListRequestBody, DomainList, UploadDomainListRequestBody } from '../types/domain-list';

export default {
  upload: async (data: UploadDomainListRequestBody): Promise<DomainList> => {
    const response = await axios.post('/domain-lists/upload', data);

    return response.data;
  },
  add: async (data: AddDomainListRequestBody): Promise<DomainList> => {
    const response = await axios.post('/domain-lists', data);

    return response.data;
  },
  update: async (id: string | number, data: { name: string }): Promise<DomainList> => {
    const response = await axios.put(`/domain-lists/${id}`, data);

    return response.data;
  },
  delete: async (id: string | number): Promise<DomainList> => {
    const response = await axios.delete(`/domain-lists/${id}`);

    return response.data;
  },
};
