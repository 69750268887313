import axios from 'services/api/axios';
import { DeviceModel } from '../types/device-models';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<DeviceModel>> => {
    const response = await axios.get(queryString ? `/device-models?${queryString}` : '/device-models');

    return response.data;
  },
};
