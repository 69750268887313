// material-ui
import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
`;
