import React, { memo } from 'react';
// material-ui
import { Stack } from '@mui/material';
// libs
import { Field, Form, Formik } from 'formik';
// components
import { ActionButton, Input } from 'components';
// icons
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
// types
import { SearchFieldProps, SearchFieldValues } from './types';

/*
 * TODO(question?)
 * - would be good if we have some reset button to clean the search(some 'x' in the end of search field)
 */
const SearchField: React.FC<SearchFieldProps> = ({ placeholder, handleSubmit }) => {
  const onSubmit = (values: SearchFieldValues) => {
    handleSubmit(values);
  };

  return (
    <Formik
      initialValues={{
        search: '',
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <Stack direction='row' gap={1}>
          <Field sx={{ width: '288px' }} name='search' placeholder={placeholder} component={Input} />

          <ActionButton type='submit'>
            <SearchIcon />
          </ActionButton>
        </Stack>
      </Form>
    </Formik>
  );
};

export default memo(SearchField);
