import axios from 'services/api/axios';
import { ListItem } from '../types/lists';
import { Pagination } from '../types/pagination';
import { Zipcode, ZipcodeRequestData } from '../types/zipcodes';

export default {
  get: async (id: number, queryString?: string): Promise<Pagination<Zipcode>> => {
    const response = await axios.get(`/lists/${id}/zipcodes?${queryString}`);

    return response.data;
  },
  create: async (id: number, data: ZipcodeRequestData): Promise<ListItem['id']> => {
    const response = await axios.post(`/lists/${id}/zipcodes`, data);

    return response.data;
  },
  // createFromCSV: async (id: ListItem['id'], data: BundleCSVRequestData): Promise<BundleResponseData> => {
  //   const response = await axios.post(`/lists/${id}/zipcodes-upload`, data);

  //   return response.data;
  // },
  delete: async (id: number, queryString?: string): Promise<void> => {
    const response = await axios.delete(`/lists/${id}/zipcodes?${queryString}`);

    return response.data;
  },
};
