import React from 'react';
// material-ui
import { CircularProgress, Typography } from '@mui/material';
// styles
import { Wrapper } from './styled';
import { gray } from 'themes/colors';
// types
import { LoaderProps } from './types';

const Loader: React.FC<LoaderProps> = ({ title = 'Loading...', ...props }) => (
  <Wrapper {...props}>
    <CircularProgress size={24} />

    <Typography variant='body2' fontWeight={500} color={gray[800]}>
      {title}
    </Typography>
  </Wrapper>
);

export default Loader;
