// material-ui
import { IconButton, Popover, styled } from '@mui/material';
import { Form } from 'formik';
// styles
import { gray } from 'themes/colors';

export const StyledIconButton = styled(IconButton)`
  &:hover {
    & svg {
      fill: ${gray[300]};
    }
  }
`;

export const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    width: 100%;
    max-width: 365px;
    margin-top: 5px;
    padding: 10px 16px;
    border-color: ${gray[200]};
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  gap: 15px;
`;
