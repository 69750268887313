import { useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import api from 'services/api';
import { CalculateAbmReportParams } from 'services/api/types/amb-audiences';
import { PageHeader } from 'components';
import Charts from './components/Charts';
import Filter from './components/Filter';
import classes from './components/styles.module.css';
import TableTabs from './components/TableTabs';
import { TableContextProvider } from './components/TableTabs/ReportTable/reportTable.context';
import { getOrderByTabIndex, prepareReportsRequestData, prepareRequestData } from './helpers';
import { FormData } from './components/Filter/types';

const ABMReports = () => {
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  const [currentTab, setCurrentTab] = useState<number>(0);

  const [filterData, setFilterData] = useState<FormData | null>(null);

  const [file, setFile] = useState<any[] | null>(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: CalculateAbmReportParams) => api.abmAudiences.calculate(data),
    onSuccess: (data) => {
      setFile(data);
    },
  });

  const handleFilterSubmit = (values: FormData) => {
    setFilterData(values);

    mutate(prepareRequestData(values, getOrderByTabIndex(currentTab)));
  };

  const handleFilterReset = () => {
    setFilterData(null);
  };

  const handleChangeTab = useCallback(
    (_: React.SyntheticEvent, value: number) => {
      setCurrentTab(value);
      mutate(prepareRequestData(filterData!, getOrderByTabIndex(value)));
    },
    [filterData, mutate],
  );

  const handlePrint = useReactToPrint({
    content: () => pdfContainerRef.current,
    bodyClass: classes.pdfContainer,
  });

  const handleDownloadCSV = async () => {
    const response = await api.abmAudiences.reports(prepareReportsRequestData(filterData!, 'csv'));
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div ref={pdfContainerRef}>
      <TableContextProvider>
        <PageHeader title='ABM Reporting' rightActions={<></>} />

        <Filter
          isLoading={isLoading}
          downloadDisabled={!filterData}
          onCSVDownload={handleDownloadCSV}
          onFilterApply={handleFilterSubmit}
          onPdfDownload={handlePrint}
          onFilterReset={handleFilterReset}
        />

        {file && filterData && file?.length > 0 && (
          <Charts isLoading={isLoading} currentTab={currentTab} onTabChange={handleChangeTab} data={file} />
        )}

        {filterData && <TableTabs filterData={filterData} />}

        {file?.length === 0 && (
          <Stack alignItems='center'>
            <Typography>No data</Typography>
          </Stack>
        )}
      </TableContextProvider>
    </div>
  );
};

export default ABMReports;
