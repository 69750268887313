import axios from 'services/api/axios';
import { Carrier } from '../types/carriers';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<Carrier>> => {
    const response = await axios.get(queryString ? `/carriers?${queryString}` : '/carriers');

    return response.data;
  },
};
