import axios from 'services/api/axios';
import { Pagination } from '../types/pagination';
import { SkadVersion } from '../types/skad-versions';

export default {
  getList: async (): Promise<Pagination<SkadVersion>> => {
    const response = await axios.get('/skad-versions');

    return response.data;
  },
};
