import { Alert, styled } from '@mui/material';
import { primary } from 'themes/colors';

export const MaterialUIAlert = styled(Alert)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  border-color: ${primary[300]};
  background-color: ${primary[25]};

  & .MuiAlert-icon {
    padding: 0;
    margin-right: 16px;
  }

  & .MuiAlert-message {
    padding: 0;
    color: ${primary[600]};
  }
`;
