import { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import orderby from 'lodash.orderby';
import uniq from 'lodash.uniq';
import api from 'services/api';
import { ReportsAbmReportParams, ReportsGroupBy } from 'services/api/types/amb-audiences';
import { Table } from 'components';
import { prepareReportsRequestData } from 'pages/Analytics/ABMReports/helpers';
import Filters, { FiltersState } from './components/Filters';
import { applyFilter } from './helpers';
import { useTableContext } from './reportTable.context';
import { FormData } from '../../Filter/types';

type ReportTable = {
  getColumns: (params: {
    sortState: SortState | null;
    onSort: (state: SortState | null) => void;
  }) => ColumnDef<any, any>[];
  filterData: FormData;
  groupBy?: ReportsGroupBy[];
  isFilterOpen: boolean;
};

const pageSize = 20;

export type SortState = {
  sortType: 'asc' | 'desc';
  field: string;
};

const ReportTable: FC<ReportTable> = ({ getColumns, isFilterOpen, filterData, groupBy }) => {
  const { filters, setFilters } = useTableContext();

  const [sort, setSort] = useState<SortState | null>(null);

  const [data, setData] = useState<any[]>([]);

  const [sortedData, setSortedData] = useState<any[] | null>(null);

  const { mutate: mutateReports, isLoading } = useMutation({
    mutationFn: (data: ReportsAbmReportParams) => api.abmAudiences.reports(data),
    onSuccess: (data) => {
      setData(data);
    },
  });

  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: pageSize });

  const skip = pagination.pageIndex * pageSize;

  const filteredData = useMemo(() => applyFilter(sortedData || data, filters), [data, filters, sortedData]);

  const tableData = useMemo(() => [...filteredData.slice(skip, skip + pageSize)], [filteredData, skip]);

  const pageCount = filteredData ? Math.ceil(filteredData.length / pageSize) : undefined;

  const domainsList = useMemo(() => uniq(data.map((item) => item.domainName)), [data]);

  const handleChangeFilter = (data: FiltersState) => {
    setPagination((prevPaginationState) => ({ ...prevPaginationState, pageIndex: 0 }));

    setFilters(data);
  };

  useEffect(() => {
    if (sort) {
      setSortedData(orderby(data, sort.field, sort.sortType));
    } else {
      setSortedData(null);
    }
  }, [data, sort]);

  useEffect(() => {
    mutateReports(prepareReportsRequestData(filterData, 'json', groupBy));
  }, [filterData, groupBy, mutateReports]);

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Filters isOpen={isFilterOpen} domainsList={domainsList} filters={filters} onChange={handleChangeFilter} />

      <Table
        columns={getColumns({ sortState: sort, onSort: setSort })}
        data={tableData}
        isFetching={isLoading}
        pagination={pagination}
        handlePagination={setPagination}
        pageCount={pageCount}
      />
    </Box>
  );
};

export default ReportTable;
