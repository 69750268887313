// material-ui
import { styled } from '@mui/material';
// components
import SimpleBar from 'simplebar-react';
// styles
import { gray } from 'themes/colors';

export const StyledSimpleBar = styled(SimpleBar)`
  width: 100%;
  padding-bottom: 12px;

  & .simplebar-track.simplebar-horizontal {
    height: 8px;

    & .simplebar-scrollbar::before {
      border-radius: 8px;
      background-color: ${gray[200]};
      top: 0;
      bottom: 0;
      right: 4px;
      left: 4px;
    }

    & .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
  }

  & .simplebar-track.simplebar-vertical {
    width: 8px;

    & .simplebar-scrollbar::before {
      border-radius: 8px;
      background-color: ${gray[200]};
      top: 4px;
      bottom: 4px;
      right: 0;
      left: 0;
    }

    & .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
  }
`;
