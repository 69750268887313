// material-ui
import { Box, styled } from '@mui/material';
// components
import Loader from 'components/Loader';
// styles
import { gray, primary } from 'themes/colors';

export const DropzoneContainer = styled(Box)`
  border: 1px solid ${gray[200]};
  border-radius: 8px;
  background-color: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ${primary[500]};
`;

export const StyledLoader = styled(Loader)`
  max-height: 127px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid ${gray[200]};
`;
