import axios from 'services/api/axios';
import { DeviceLanguage } from '../types/device-languages';
import { Pagination } from '../types/pagination';

export default {
  getList: async (queryString?: string): Promise<Pagination<DeviceLanguage>> => {
    const response = await axios.get(queryString ? `/device-languages?${queryString}` : '/device-languages');

    return response.data;
  },
};
