// material-ui
import { styled } from '@mui/material';
// styles
import { gray, primary } from 'themes/colors';

export const StyledTable = styled('table')`
  border-spacing: 0 4px;
  width: 100%;
  min-height: 150px;
`;

export const TableHeader = styled('thead')`
  background-color: ${primary[1000]};
  color: ${gray[300]};
  font-size: 12px;
  line-height: 18px;
  text-align: left;
`;

export const TableHeaderCell = styled('th')`
  height: 44px;
  padding: 0 12px;
  font-weight: 600;

  &:first-of-type {
    border-radius: 6px 0 0 6px;
  }

  &:last-of-type {
    border-radius: 0 6px 6px 0;
  }
`;

export const TableRow = styled('tr')<{ isSelected: boolean; isChildRow: boolean }>`
  background-color: ${({ isChildRow }) => (isChildRow ? gray[50] : '#fff')};
  height: 64px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${gray[900]};

  ${({ isSelected }) =>
    isSelected &&
    `
      background-color: ${gray[50]};
  `};
`;

export const TableRowCell = styled('td')`
  padding: 0 12px;

  &:first-of-type {
    border-radius: 6px 0 0 6px;
  }

  &:last-of-type {
    border-radius: 0 6px 6px 0;
  }
`;

export const TableTotalRow = styled('tr')`
  background-color: ${gray[100]};
  height: 64px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${gray[900]};
`;

export const HeaderWrapper = styled('div')<{ isSort: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ isSort }) =>
    isSort &&
    `
      cursor: pointer;
  `};
`;
