// material-ui
import { Link } from 'react-router-dom';
import { IconButton, MenuItem, styled } from '@mui/material';
// styles
import { gray } from 'themes/colors';

export const Wrapper = styled('div')<{ isMenuOpen: boolean }>`
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    & button > svg {
      fill: ${gray[800]};
    }
`};
`;

export const MoreButton = styled(IconButton)`
  padding: 10px;

  & > svg {
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    & > svg {
      fill: ${gray[800]};
    }
  }
`;

export const StyleMenuItem = styled(MenuItem)`
  padding: 10px 16px;
  gap: 12px;
  color: ${gray[900]};

  & > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    color: ${gray[900]};
  }

  & .MuiListItemIcon-root {
    min-width: auto;
  }

  & .MuiListItemText-primary {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    background-color: ${gray[50]};

    & .MuiListItemText-primary {
      font-weight: 500;
    }
  }
`;

export const StyledLink = styled(Link)`
  width: 100%;
`;
