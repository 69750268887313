import { certisDspAxiosInstance as axios } from 'services/api/axios';
import { AccessControl, UpdateAccessControlExternalRequestBody } from '../types/access-control';

export default {
  getList: async (): Promise<AccessControl[]> => {
    const response = await axios.get('/access-control-lists');

    return response.data;
  },
  getOneExternal: async (id: string | number): Promise<AccessControl> => {
    const response = await axios.get(`/access-control-lists/external/${id}`);

    return response.data;
  },
  updateExternal: async (data: UpdateAccessControlExternalRequestBody): Promise<AccessControl> => {
    const response = await axios.put('/access-control-lists/external', data);

    return response.data;
  },
};
