import React from 'react';
// styles
import { StyledButton } from './styled';
// types
import { ActionButtonProps } from './types';

const ActionButton: React.FC<ActionButtonProps> = ({
  type,
  children,
  variant = 'outlined',
  color = 'primary',
  onClick,
  isActive,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      isActive={isActive}
      disableRipple
    >
      {children}
    </StyledButton>
  );
};

export default ActionButton;
