import axios from 'services/api/axios';
import { Account, PatchAccountRequestBody, PostAccountRequestBody } from '../types/accounts';
import { Pagination } from '../types/pagination';

export default {
  getListWithPagination: async (queryString?: string): Promise<Pagination<Account>> => {
    const response = await axios.get(`/accounts?${queryString}`);

    return response.data;
  },
  getList: async (): Promise<Account[]> => {
    const response = await axios.get('/accounts?$skip=0&$limit=10000');

    return response.data;
  },
  getOne: async (id: number | string): Promise<Account> => {
    const response = await axios.get(`/accounts/${id}`);

    return response.data;
  },
  updateOne: async (id: number | string, data: PatchAccountRequestBody): Promise<Account> => {
    const response = await axios.patch(`/accounts/${id}`, data);

    return response.data;
  },
  create: async (data: PostAccountRequestBody): Promise<Account> => {
    const response = await axios.post('/accounts/', data);

    return response.data;
  },
};
