import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
// libs
import {
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { AxiosError } from 'axios';
import merge from 'lodash.merge';
import api from 'services/api';
import { ConfigName, CreateUpdateInterfaceConfigRequestBody } from 'services/api/types/interface-configs';
// components
import ScrollableContent from 'components/ScrollableContent';
import Loader from '../Loader';
import MobileTablePagination from './components/MobileTablePagination';
import TableFilter from './components/TableFilter';
import TablePagination from './components/TablePagination';
import TablePickColumnsModal from './components/TablePickColumnsModal';
// icons
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as SortArrowDownIcon } from 'assets/icons/sort-arrow-down.svg';
import { ReactComponent as SortArrowUpIcon } from 'assets/icons/sort-arrow-up.svg';
// styles
import { HeaderWrapper, StyledTable, TableHeader, TableHeaderCell, TableRow, TableRowCell } from './styled';
// types
import { TableProps } from './types';

import 'simplebar-react/dist/simplebar.min.css';

const Table: React.FC<TableProps> = ({
  data,
  columns,
  pageCount,
  isRowLoading = {},
  isFetching = false,
  isPickColumnsOpen = false,
  manualPagination = true,
  pagination = { pageIndex: 0, pageSize: 10 },
  defaultHiddenColumns = {},
  defaultColumnFilters = [],
  handlePagination,
  handleExpandedClick,
  handlePickColumnsClose,
  rowSelection = {},
  setRowSelection,
  setSelectedFaltRows,
  onFilterChange,
  totalRow,
  sorting,
  setSorting,
  saveConfig,
  configName,
}) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { mutate } = useMutation({
    mutationFn: (data: CreateUpdateInterfaceConfigRequestBody) =>
      api.interfaceConfigs.createUpdate(configName as ConfigName, data),
  });

  const { isFetching: isConfigFetching, error } = useQuery(
    ['interfaceConfig', configName],
    () => (configName ? api.interfaceConfigs.getOne(configName) : null),
    {
      enabled: Boolean(saveConfig && configName),
      onSuccess: (data) => setColumnVisibility(data?.config.columnVisibility),
      retry: false,
    },
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(defaultColumnFilters);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultHiddenColumns);

  const paginationOptions = useMemo(
    () =>
      manualPagination
        ? {
            pageCount: pageCount ?? -1,
            state: {
              pagination,
            },
            onPaginationChange: handlePagination,
            manualPagination: true,
          }
        : {
            getPaginationRowModel: getPaginationRowModel(),
          },
    [manualPagination, pageCount, pagination, handlePagination],
  );

  const handleUpdateConfig = useCallback(
    (data: VisibilityState) => mutate({ config: { columnVisibility: data } }),
    [mutate],
  );

  const table = useReactTable(
    merge(
      {
        data,
        columns,
        state: {
          columnVisibility,
          columnFilters,
          rowSelection,
          expanded,
          ...(sorting && { sorting }),
        },
        ...(setSorting && { onSortingChange: setSorting }),
        onExpandedChange: setExpanded,
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        onColumnFiltersChange: setColumnFilters,
        getRowCanExpand: () => true,
        getSubRows: (row: any) => row.subRows || [],
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getPaginationRowModel: getPaginationRowModel(),
        autoResetExpanded: false,
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
        manualSorting: Boolean(sorting),
      },
      paginationOptions,
    ),
  );

  useEffect(() => {
    setSelectedFaltRows?.(table.getSelectedRowModel().flatRows);
  }, [table, rowSelection, setSelectedFaltRows]);

  // If we don't have config on service side we send initial values
  useEffect(() => {
    if ((error as AxiosError<Error>)?.response?.status === 404) {
      mutate({ config: { columnVisibility } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, mutate]);

  if (isConfigFetching) {
    return <CircularProgress size={28} sx={{ alignSelf: 'center' }} />;
  }

  return (
    <>
      <ScrollableContent>
        {(isFetching || isConfigFetching) && <Loader sx={{ top: '52px' }} />}

        <StyledTable>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeaderCell
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ minWidth: header.getSize() !== 150 ? header.getSize() : undefined }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <HeaderWrapper
                          isSort={header.column.getCanSort()}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}

                          {header.column.getCanSort()
                            ? {
                                asc: <SortArrowUpIcon />,
                                desc: <SortArrowDownIcon />,
                              }[header.column.getIsSorted() as string] ?? <SortIcon />
                            : null}

                          {header.column.getCanFilter() ? (
                            <TableFilter onFilterChange={onFilterChange} column={header.column} />
                          ) : null}
                        </HeaderWrapper>
                      </>
                    )}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </TableHeader>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} isSelected={row.getIsSelected()} isChildRow={!!row.parentId}>
                {row.getVisibleCells().map((cell) => (
                  <TableRowCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      handleExpandedClick,
                      isLoading: isRowLoading[row.id],
                      isExpanded: (expanded as Record<string, boolean>)[row.id],
                    })}
                  </TableRowCell>
                ))}
              </TableRow>
            ))}

            {totalRow && React.cloneElement(totalRow, { table })}
          </tbody>
        </StyledTable>
      </ScrollableContent>

      {matches ? <TablePagination table={table} /> : <MobileTablePagination table={table} />}

      <TablePickColumnsModal
        onSubmit={handleUpdateConfig}
        isOpen={isPickColumnsOpen}
        table={table}
        handleClose={handlePickColumnsClose}
      />
    </>
  );
};

export default memo(Table);
