import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDatePicker, {
  CalendarContainer,
  CalendarContainerProps,
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';
import { Box, FormHelperText, Popper } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { SideBarItemID, useSideBarItems } from './constants';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import {
  Placeholder,
  StyledButton,
  StyledCalendarsContainer,
  StyledSidebarContainer,
  StyledSideBarItem,
} from './styled';

import 'react-datepicker/dist/react-datepicker.css';
import './dateRangePicker.css';

const renderDaysContent = (dayOfMonth: number, date: Date) => {
  return <div className='datePickerDayWrapper'>{dayOfMonth}</div>;
};

const renderCustomHeader = ({ monthDate, date, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' height='36px'>
      <Box sx={{ cursor: 'pointer' }} onClick={decreaseMonth}>
        <ArrowLeft />
      </Box>
      <Box
        sx={{
          fontFamily: 'Gilroy',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: 0,
          textAlign: 'center',
        }}
      >
        {moment(monthDate).format('MMMM')} {moment(date).format('yyyy')}
      </Box>

      <Box sx={{ cursor: 'pointer' }} onClick={increaseMonth}>
        <ArrowRight />
      </Box>
    </Box>
  );
};

const CalendarWrapper = ({
  className,
  children,
  startDateName,
  endDateName,
  hiddenSideBarItems,
  onChangeHelper,
}: CalendarContainerProps & {
  hiddenSideBarItems?: SideBarItemID[];
  startDateName: string;
  endDateName: string;
  onChangeHelper?: (startDateName?: Date | null, endDate?: Date | null) => void;
}) => {
  const { values } = useFormikContext<any>();

  const sideBarItems = useSideBarItems({ startDateName, endDateName });

  return (
    <CalendarContainer className={className}>
      <StyledCalendarsContainer>{children}</StyledCalendarsContainer>
      <StyledSidebarContainer>
        {sideBarItems.map((item, i) => {
          if (hiddenSideBarItems?.includes(item.id)) {
            return null;
          }

          const isActive =
            moment(values[startDateName]).format('DD MM YYYY') === moment(item.startDate).format('DD MM YYYY') &&
            moment(values[endDateName]).format('DD MM YYYY') === moment(item.endDate).format('DD MM YYYY');

          return (
            <StyledSideBarItem
              isActive={isActive}
              key={i}
              onClick={() => {
                item.onClick();
                onChangeHelper?.(item.startDate, item.endDate);
              }}
            >
              {item.title}
            </StyledSideBarItem>
          );
        })}
      </StyledSidebarContainer>
    </CalendarContainer>
  );
};

interface DateRangePickerProps {
  startDateName: string;
  endDateName: string;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  onChangeHelper?: (startDateName?: Date | null, endDate?: Date | null) => void;
  hiddenSideBarItems?: SideBarItemID[];
}

const DateRangePicker = ({
  startDateName,
  endDateName,
  maxDate,
  minDate,
  disabled,
  hiddenSideBarItems,
  onChangeHelper,
}: DateRangePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { values, setFieldValue, errors } = useFormikContext<any>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const startDate = values[startDateName];
  const endDate = values[endDateName];

  const calendarRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFieldValue(startDateName, start, false);
    setFieldValue(endDateName, end, false);
    onChangeHelper?.(start, end);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const onClickOutside = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (event.target === buttonRef.current || buttonRef.current?.contains(event.target as Node)) {
        return;
      }

      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <Box position='relative' width={250}>
      <StyledButton type='button' onClick={handleClick} ref={buttonRef} disabled={disabled}>
        <Box width={20} height={20}>
          <CalendarIcon />
        </Box>
        {startDate ? `${moment(startDate).format('DD/MM/YYYY')} - ` : ''}
        {endDate ? moment(endDate ?? '').format('DD/MM/YYYY') : ''}
        {!startDate ? <Placeholder>dd/mm/yyyy - dd/mm/yyyy</Placeholder> : null}
      </StyledButton>

      {(errors[startDateName] || errors[endDateName]) && (
        <FormHelperText error>{(errors[startDateName] as string) || (errors[endDateName] as string)}</FormHelperText>
      )}

      <Popper anchorEl={anchorEl} open={isOpen} ref={calendarRef} sx={{ padding: '0px 20px', zIndex: 1 }}>
        <ReactDatePicker
          maxDate={maxDate}
          minDate={minDate}
          selected={values[startDateName]}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          renderDayContents={renderDaysContent}
          calendarClassName='calendarClassName'
          showPopperArrow={false}
          inline
          calendarContainer={(props) => (
            <CalendarWrapper
              hiddenSideBarItems={hiddenSideBarItems}
              onChangeHelper={onChangeHelper}
              {...props}
              startDateName={startDateName}
              endDateName={endDateName}
            />
          )}
          renderCustomHeader={renderCustomHeader}
          withPortal
        />
      </Popper>
    </Box>
  );
};

export default DateRangePicker;
