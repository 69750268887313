// material-ui
import { MenuItem, Select, styled, Typography } from '@mui/material';
// styles
import { gray } from 'themes/colors';
// types
import { SelectProps } from './types';

export const StyledSelect = styled(Select<SelectProps['value']>)`
  min-width: 75px;
  box-shadow: none;
  & > .MuiSelect-icon {
    right: 14px;
    top: auto;
  }
`;

export const StyledSelectItem = styled(MenuItem)`
  gap: 8px;
  padding: 10px 14px;
  color: ${gray[900]};
  justify-content: space-between;

  & .MuiListItemText-primary {
    font-size: 16px;
    line-height: 24px;
  }

  &&.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    background-color: ${gray[50]};
  }
`;

export const Placeholder = styled(Typography)`
  font-weight: 500;
  color: ${gray[500]};
`;
