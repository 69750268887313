export const primary = {
  25: '#FCFCFD',
  50: '#F2F1F9',
  100: '#E6E2F3',
  200: '#CDC6E7',
  300: '#B4A9DA',
  400: '#9B8DCE',
  500: '#7B69BE',
  600: '#6954B6',
  700: '#57439D',
  800: '#473781',
  900: '#372B64',
  1000: '#271D3B',
};

export const secondary = {
  500: '#2FBE92',
};

export const gray = {
  25: '#FCFDFF',
  50: '#F8FAFE',
  100: '#F1F4FB',
  200: '#E7ECF8',
  300: '#CFD5E5',
  400: '#94A0BD',
  500: '#647193',
  600: '#445171',
  700: '#333D55',
  800: '#1D2939',
  900: '#101828',
};

export const error = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
};

export const success = {
  50: '#ECFDF3',
  500: '#12B76A',
  700: '#027A48',
};

export const orange = {
  50: '#FFF6ED',
  700: '#C4320A',
};
