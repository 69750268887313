import * as React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import {
  AutocompleteProps as MUIAutocompleteProps,
  Chip,
  CircularProgress,
  FormControl,
  FormLabel,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FieldProps, getIn } from 'formik';
import { OPTIONS_KEYS } from 'services/constants';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/cross.svg';
import { StyledOptionItem } from '../Autocomplete/styled';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return dataSet[3] && !dataSet[4] ? (
    <StyledOptionItem {...dataSet[0]} style={inlineStyle}>
      {dataSet[1].label} {dataSet[2] ? <CheckIcon /> : null}
    </StyledOptionItem>
  ) : (
    <li {...dataSet[0]} style={inlineStyle}>
      {dataSet[1].label}
    </li>
  );
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref,
) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = () => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={() => getChildSize()}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export type OptionValue = string | number | null;

export type Option = {
  label: string;
  value: OptionValue;
};

export type AutocompleteProps<T extends Option> = FieldProps & {
  label: string;
  placeholder?: string;
  multiple?: boolean;
  required?: boolean;
  disableCheckIcon?: boolean;
  isOptionImage?: boolean;
  isLoading?: boolean;
  options: T[];
  loading?: boolean;
  helperText?: string;
  optionsKey?: OPTIONS_KEYS;
  optionsFetchParams?: Record<string, string>;
  extractLabel?: (option: T) => string;
  extractValue?: (option: T) => OptionValue;
  setValueOnClose?: boolean;
  overridePrevValue?: boolean;
};
const AutocompleteVirtualize = <T extends Option>(
  props: AutocompleteProps<T> &
    Omit<MUIAutocompleteProps<T, boolean, boolean, boolean>, 'options' | 'value' | 'title' | 'renderInput'> & {
      isValueObject?: boolean;
    },
) => {
  const {
    limitTags,
    label,
    field,
    required,
    placeholder,
    multiple = false,
    disableCheckIcon,
    helperText,
    options,
    isLoading,
    isValueObject = false,
    form: { errors, touched, setFieldValue },
    extractValue = (option: T) => option.value,
    extractLabel = (option: T) => option.label,
    setValueOnClose,
    overridePrevValue,
    ...rest
  } = props;

  const [value, setValue] = React.useState<any>(multiple ? [] : null);

  const errorMessage = getIn(errors, field.name);
  const isError = !!(errorMessage && getIn(touched, field.name));

  const handleClose = () => {
    if (setValueOnClose) {
      const newValue = multiple
        ? (value as T[]).map((opt: T) => {
            return extractValue(opt);
          })
        : extractValue(value as T);

      setFieldValue(field.name, newValue);
    }
  };

  const handleChange = React.useCallback<NonNullable<MUIAutocompleteProps<T, boolean, boolean, boolean>['onChange']>>(
    (e, option) => {
      const newValue = multiple
        ? (option as T[]).map((opt: T) => {
            return extractValue(opt);
          })
        : extractValue(option as T);

      setValue(option);

      // TODO: refactor?
      if (!setValueOnClose) {
        setFieldValue(field.name, isValueObject ? option : newValue);
      }
    },
    [multiple, extractValue, setValueOnClose, setFieldValue, field.name, isValueObject],
  );

  // effect needed for displaying data at editing
  React.useEffect(() => {
    if (!(value && value.length) && field.value && options.length) {
      setValue(
        multiple
          ? [...value, ...options.filter((option) => field.value.find((value: any) => value === option.value))]
          : options.find((option) => option.value === field.value),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, options]);

  React.useEffect(() => {
    if (overridePrevValue) {
      setValue(
        multiple
          ? [...options.filter((option) => field.value.find((value: any) => value === option.value))]
          : options.find((option) => option.value === field.value),
      );
    }
  }, [field.value, multiple, options, overridePrevValue]);

  // TODO: bad solution to enable form.resetForm() function, need to refactor
  React.useEffect(() => {
    if (multiple ? !field.value.length : !field.value) {
      setValue(field.value);
    }
  }, [field.value, multiple]);

  return (
    <Autocomplete
      {...field}
      fullWidth
      disableClearable
      multiple={multiple}
      loading={isLoading}
      value={value}
      ListboxComponent={ListboxComponent}
      options={options}
      onChange={handleChange}
      getOptionLabel={(option: any) => extractLabel(option)}
      disableCloseOnSelect={multiple}
      popupIcon={<ChevronDownIcon />}
      ChipProps={{ deleteIcon: <DeleteIcon /> }}
      isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
      onClose={handleClose}
      renderTags={(values, getTagProps) => {
        return values
          .map((value, index) => {
            if (limitTags && index >= limitTags) {
              return `+${values.length - limitTags}`;
            }
            return <Chip label={value.label} {...getTagProps({ index })} key={index} deleteIcon={<DeleteIcon />} />;
          })
          .filter((value, index, array) => {
            return array.indexOf(value) === index;
          });
      }}
      renderInput={(params) => {
        return (
          <FormControl fullWidth>
            {!!label && <FormLabel required={required}>{label}</FormLabel>}

            <TextField
              {...params}
              error={isError}
              placeholder={value?.length ? undefined : placeholder}
              helperText={isError ? errorMessage : helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress size={16} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </FormControl>
        );
      }}
      renderOption={(props, option, { selected }) =>
        [props, option, selected, multiple, disableCheckIcon] as React.ReactNode
      }
      {...rest}
    />
  );
};

export default AutocompleteVirtualize;
