import axios, { certisDspAxiosInstance } from 'services/api/axios';
import { Pagination } from '../types/pagination';
import { PatchUserRequestBody, PostUserRequestBody, User } from '../types/users';

export default {
  getList: async (queryString?: string): Promise<Pagination<User>> => {
    const response = await axios.get(`/users?${queryString}`);

    return response.data;
  },
  getOne: async (id: number | string): Promise<User> => {
    const response = await axios.get(`/users/${id}`);

    return response.data;
  },
  updateOne: async (id: number | string, data: PatchUserRequestBody): Promise<User> => {
    const response = await axios.patch(`/users/${id}`, data);

    return response.data;
  },
  create: async (data: PostUserRequestBody): Promise<User> => {
    const response = await axios.post('/users/', data);

    return response.data;
  },
  trackMe: async (): Promise<any> => {
    const response = await certisDspAxiosInstance.post('/users/me/track');

    return response;
  },
};
